import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import moment from "moment";

import makeStyles from "@mui/styles/makeStyles";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { alertMessage } from "../actions/auth";
import { Bar } from "react-chartjs-2";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Chart } from "chart.js/auto";
import { Line } from "react-chartjs-2";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Fab } from "@mui/material";
import Fade from "@mui/material/Fade";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";

import {
  PRODUCTION,
  SERVER_API_ADDRESS,
  LOCAL_API_ADDRESS,
} from "../actions/auth";

function Register() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(true);
  const [vendorId, setVendorId] = useState(
    params.get("vendor_id") ? params.get("vendor_id") : null
  );
  const [vendorData, setVendorData] = useState({});
  const [deliveryData, setDeliveryData] = useState({});
  const [reportsPage, setReportsPage] = useState("ORDERS");
  const [seeChart, setSeeChart] = useState(false);
  const [clearType, setClearType] = useState("");

  const [vendorIdList, setVendorIdList] = useState([]);
  const [deliveryPartnerIdList, setDeliveryPartnerIdList] = useState([]);

  const [selectedPage, setSelectedPage] = useState(
    params.get("tab") ? params.get("tab") : "VENDOR"
  );
  const [deliveryPartnerId, setDeliveryPartnerId] = useState();
  const [vendorPaymentData, setVendorPaymentData] = useState({});
  const [deliveryPaymentData, setDeliveryPaymentData] = useState({});
  const [open2, setOpen2] = useState(false);
  const [order, setOrder] = useState("");
  const [open, setOpen] = useState(false);
  const [vendorName, setVendorName] = useState("");
  const [partnerName, setDeliveryPartnerName] = useState("");

  const handleOpen2 = (order) => {
    setOrder(order);
    setOpen2(true);
  };

  const handlePageSelect = (event) => {
    setSelectedPage(event.currentTarget.value);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function clearAllDues() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };
    if (clearType == "VENDOR") {
      body["vendor_id"] = vendorId["vendor_id"];
      body["clear_type"] = "VENDOR";
    } else {
      body["delivery_partner_id"] = deliveryPartnerId["delivery_partner_id"];
      body["clear_type"] = "DELIVERY";
    }
    body = JSON.stringify(body);

    axios
      .post(
        PRODUCTION
          ? SERVER_API_ADDRESS + "api/clear_all_dues"
          : LOCAL_API_ADDRESS + "api/clear_all_dues",
        body,
        config
      )
      .then(() => {
        if (clearType == "VENDOR") {
          getVendorReports(vendorId["vendor_id"]);
          setOpen(false);
        } else {
          getDeliveryReports(deliveryPartnerId["delivery_partner_id"]);
          setOpen(false);
        }
      });
  }

  function getVendorList() {
    var vendors = vendorIdList.map((vendor, index) => (
      <MenuItem value={vendor}>{vendor["vendor_name"]}</MenuItem>
    ));
    return vendors;
  }

  function getDeliveryPartnerList() {
    var partners = deliveryPartnerIdList.map((deliveryPartner, index) => (
      <MenuItem value={deliveryPartner}>
        {deliveryPartner["partner_name"]}
      </MenuItem>
    ));
    return partners;
  }

  function getVendorReports(vendor_id) {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };
    body["vendor_id"] = vendor_id;
    body = JSON.stringify(body);

    axios
      .post(
        PRODUCTION
          ? SERVER_API_ADDRESS + "api/vendor_reports"
          : LOCAL_API_ADDRESS + "api/vendor_reports",
        body,
        config
      )
      .then((res) => {
        axios
          .post(
            PRODUCTION
              ? SERVER_API_ADDRESS + "api/vendor_pending_payments"
              : LOCAL_API_ADDRESS + "api/vendor_pending_payments",
            body,
            config
          )
          .then((res) => {
            setVendorPaymentData(res.data["data"]);
            setIsLoading(false);
          });
        setVendorData(res.data["data"]);
      });
  }

  function getDeliveryReports(delivery_partner_id) {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };

    body["delivery_partner_id"] = delivery_partner_id;
    body = JSON.stringify(body);
    axios
      .post(
        PRODUCTION
          ? SERVER_API_ADDRESS + "api/delivery_reports"
          : LOCAL_API_ADDRESS + "api/delivery_reports",
        body,
        config
      )
      .then((res) => {
        axios
          .post(
            PRODUCTION
              ? SERVER_API_ADDRESS + "api/delivery_pending_payments"
              : LOCAL_API_ADDRESS + "api/delivery_pending_payments",
            body,
            config
          )
          .then((res) => {
            setDeliveryPaymentData(res.data["data"]);
            setIsLoading(false);
          });
        setDeliveryData(res.data["data"]);
      });
  }

  function getData() {
    axios
      .get(
        PRODUCTION
          ? SERVER_API_ADDRESS + "api/get_vendors_list"
          : LOCAL_API_ADDRESS + "api/get_vendors_list"
      )
      .then((res) => {
        setVendorIdList(res.data["data"]);
        setVendorId(res.data["data"][0]);
        setVendorName(res.data["data"][0]["vendor_name"]);
        getVendorReports(res.data["data"][0]["vendor_id"]);
      });
    axios
      .get(
        PRODUCTION
          ? SERVER_API_ADDRESS + "api/get_delivery_partners_list"
          : LOCAL_API_ADDRESS + "api/get_delivery_partners_list"
      )
      .then((res) => {
        setDeliveryPartnerIdList(res.data["data"]);
        setDeliveryPartnerId(res.data["data"][0]);
        setDeliveryPartnerName(res.data["data"][0]["partner_name"]);
        getDeliveryReports(res.data["data"][0]["delivery_partner_id"]);
      });
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      getData();
    }
  }, []);

  if (state.auth.refresh == "REFRESH_DATA") {
    getData();
  }

  const vendorOrdersChart = {
    dataLine: {
      labels: [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ],
      datasets: [
        {
          label: "Vendor Orders",
          fill: true,
          lineTension: 0.3,
          backgroundColor: "rgba(225, 204,230, .3)",
          borderColor: "purple",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "red",
          pointBackgroundColor: "red",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "red",
          pointHoverBorderColor: "red",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data:
            vendorData.length == 0
              ? []
              : [
                  vendorData["feb_orders"],
                  vendorData["jan_orders"],
                  vendorData["mar_orders"],
                  vendorData["apr_orders"],
                  vendorData["may_orders"],
                  vendorData["jun_orders"],
                  vendorData["jul_orders"],
                  vendorData["aug_orders"],
                  vendorData["sept_orders"],
                  vendorData["oct_orders"],
                  vendorData["nov_orders"],
                  vendorData["dec_orders"],
                ],
        },
      ],
    },
  };

  const vendorTotalChart = {
    dataLine: {
      labels: [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ],
      datasets: [
        {
          label: "Vendor Payments",
          fill: true,
          lineTension: 0.3,
          backgroundColor: "rgba(225, 204,230, .3)",
          borderColor: "purple",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "red",
          pointBackgroundColor: "red",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "red",
          pointHoverBorderColor: "red",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data:
            vendorData.length == 0
              ? []
              : [
                  vendorData["jan_total"],
                  vendorData["feb_total"],
                  vendorData["mar_total"],
                  vendorData["apr_total"],
                  vendorData["may_total"],
                  vendorData["jun_total"],
                  vendorData["jul_total"],
                  vendorData["aug_total"],
                  vendorData["sept_total"],
                  vendorData["oct_total"],
                  vendorData["nov_total"],
                  vendorData["dec_total"],
                ],
        },
      ],
    },
  };

  const deliveryTotalChart = {
    dataLine: {
      labels: [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ],
      datasets: [
        {
          label: "Delivery Payments",
          fill: true,
          lineTension: 0.3,
          backgroundColor: "rgba(225, 204,230, .3)",
          borderColor: "purple",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "red",
          pointBackgroundColor: "red",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "red",
          pointHoverBorderColor: "red",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data:
            deliveryData.length == 0
              ? []
              : [
                  deliveryData["jan_total"],
                  deliveryData["feb_total"],
                  deliveryData["mar_total"],
                  deliveryData["apr_total"],
                  deliveryData["may_total"],
                  deliveryData["jun_total"],
                  deliveryData["jul_total"],
                  deliveryData["aug_total"],
                  deliveryData["sept_total"],
                  deliveryData["oct_total"],
                  deliveryData["nov_total"],
                  deliveryData["dec_total"],
                ],
        },
      ],
    },
  };

  const deliveryOrdersChart = {
    dataLine: {
      labels: [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ],
      datasets: [
        {
          label: "Delivery Orders",
          fill: true,
          lineTension: 0.3,
          backgroundColor: "rgba(225, 204,230, .3)",
          borderColor: "purple",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "red",
          pointBackgroundColor: "red",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "red",
          pointHoverBorderColor: "red",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data:
            deliveryData.length == 0
              ? []
              : [
                  deliveryData["jan_orders"],
                  deliveryData["feb_orders"],
                  deliveryData["mar_orders"],
                  deliveryData["apr_orders"],
                  deliveryData["may_orders"],
                  deliveryData["jun_orders"],
                  deliveryData["jul_orders"],
                  deliveryData["aug_orders"],
                  deliveryData["sept_orders"],
                  deliveryData["oct_orders"],
                  deliveryData["nov_orders"],
                  deliveryData["dec_orders"],
                ],
        },
      ],
    },
  };

  function getStatusDiv(order) {}

  function getVendorAmount(vendorPrice, quantity, type) {
    if (type == 1) {
      return vendorPrice;
    } else if (type == 2) {
      var total = 0;
      for (var i = 0; i < Object.keys(quantity).length; i++) {
        total +=
          vendorPrice[Object.keys(quantity)[i]] *
          quantity[Object.keys(quantity)[i]];
      }
      return total;
    } else if (type == 3) {
      var total = 0;
      for (var i = 0; i < Object.keys(quantity).length; i++) {
        if (Object.keys(quantity)[i] != "extras") {
          total +=
            vendorPrice[Object.keys(quantity)[i]] *
            quantity[Object.keys(quantity)[i]];
        }
      }
      return total;
    }
  }

  function getNumberOfPortions(vendorPrice, quantity, type) {
    if (type == 1) {
      return (
        <div style={{ padding: "15px" }}>
          Vendor Price:{vendorPrice} x {quantity}
        </div>
      );
    } else if (type == 2) {
      return (
        <div style={{ padding: "15px" }}>
          {Object.keys(quantity).map((quant) => (
            <div>
              <div>
                Vendor Price: {quant} : {vendorPrice[quant]} x {quantity[quant]}
              </div>
            </div>
          ))}
        </div>
      );
    } else if (type == 3) {
      return (
        <div style={{ padding: "15px" }}>
          {Object.keys(quantity).map((quant) => (
            <div>
              {quant !== "extras" ? (
                <div>
                  Vendor Price: {quant} : {vendorPrice[quant]} x{" "}
                  {quantity[quant]}
                </div>
              ) : (
                ""
              )}
            </div>
          ))}
        </div>
      );
    }
  }

  function getSelectedPageContent() {
    if (selectedPage == "VENDOR") {
      return (
        <div>
          <Grid container spacing={1.5}>
            <Grid item md={8}>
              <Select
                value={vendorId}
                onChange={(e) => {
                  setVendorId(e.target.value);
                  setVendorName(e.target.value["vendor_name"]);
                  getVendorReports(e.target.value["vendor_id"]);
                }}
                style={{ marginTop: "15px", height: "40px" }}
                fullWidth
                variant={"outlined"}
              >
                {getVendorList()}
              </Select>
            </Grid>
            <Grid item md={2}>
              <Button
                onClick={() => setSeeChart(!seeChart)}
                p={2}
                style={{
                  backgroundColor: "#1434A4",
                  color: "white",
                  height: "40px",
                  marginLeft: "0px",
                  marginTop: "15px",
                  width: "100%",
                  padding: "0px 25px 0px 25px",
                }}
              >
                <h2
                  style={{
                    fontSize: "18px",
                    color: "white",
                  }}
                >
                  {seeChart ? "HIDE CHART" : "SEE CHART"}
                </h2>{" "}
                <ChevronRightIcon
                  style={{
                    fontSize: "30px",
                    color: "white",
                    marginRight: "-10px",
                  }}
                />
              </Button>
            </Grid>
            <Grid item md={2}>
              <Button
                onClick={() => {
                  setClearType("VENDOR");
                  setOpen(true);
                }}
                p={2}
                style={{
                  backgroundColor: "#1434A4",
                  color: "white",
                  height: "40px",
                  marginLeft: "0px",
                  marginTop: "15px",
                  width: "100%",
                  padding: "0px 25px 0px 25px",
                }}
              >
                <h2
                  style={{
                    fontSize: "18px",
                    color: "white",
                  }}
                >
                  CLEAR DUES
                </h2>
                <ChevronRightIcon
                  style={{
                    fontSize: "30px",
                    color: "white",
                    marginRight: "-10px",
                  }}
                />
              </Button>
            </Grid>
          </Grid>
          <Box style={{ marginTop: "15px", width: "100%" }}>
            {seeChart ? (
              <Grid container spacing={1.5}>
                <Grid item md={6}>
                  <Line
                    height={90}
                    width={200}
                    data={vendorTotalChart.dataLine}
                    options={{ responsive: true }}
                  />
                </Grid>
                <Grid item md={6}>
                  <Line
                    height={90}
                    width={200}
                    data={vendorOrdersChart.dataLine}
                    options={{ responsive: true }}
                  />
                </Grid>
              </Grid>
            ) : (
              ""
            )}
          </Box>
          <h1>Pending amount: {vendorPaymentData["pending_payments"]}</h1>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell align="right">Date</TableCell>
                  <TableCell align="right">Order ID</TableCell>
                  <TableCell align="right">Product Name</TableCell>
                  <TableCell align="right">Payment</TableCell>
                  <TableCell align="right">Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vendorPaymentData["payment_history"].map((order, index) => (
                  <TableRow
                    key={index + 1}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1} &nbsp;
                      <u
                        onClick={() => {
                          handleOpen2(order);
                        }}
                        style={{ color: "blue", cursor: "pointer" }}
                      >
                        (details)
                      </u>
                    </TableCell>
                    <TableCell align="right">{order["date"]}</TableCell>
                    <TableCell align="right">{order["order_id"]}</TableCell>
                    <TableCell align="right">{order["product_name"]}</TableCell>
                    <TableCell align="right">
                      {order["paid"] ? "PAID" : "NOT PAID"}
                    </TableCell>
                    <TableCell align="right">
                      {getVendorAmount(
                        order["vendor_price"],
                        order["quantity"],
                        order["product_type"]
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      );
    } else {
      return (
        <div>
          <Grid container spacing={1.5}>
            <Grid item md={8}>
              <Select
                value={deliveryPartnerId}
                onChange={(e) => {
                  setDeliveryPartnerId(e.target.value);
                  setDeliveryPartnerName(e.target.value["partner_name"]);
                  getDeliveryReports(e.target.value["delivery_partner_id"]);
                }}
                style={{ marginTop: "15px", height: "40px" }}
                fullWidth
                variant={"outlined"}
              >
                {getDeliveryPartnerList()}
              </Select>
            </Grid>
            <Grid item md={2}>
              <Button
                onClick={() => setSeeChart(!seeChart)}
                p={2}
                style={{
                  backgroundColor: "#1434A4",
                  color: "white",
                  height: "40px",
                  marginLeft: "0px",
                  marginTop: "15px",
                  width: "100%",
                  padding: "0px 25px 0px 25px",
                }}
              >
                <h2
                  style={{
                    fontSize: "18px",
                    color: "white",
                  }}
                >
                  {seeChart ? "HIDE CHART" : "SEE CHART"}
                </h2>{" "}
                <ChevronRightIcon
                  style={{
                    fontSize: "30px",
                    color: "white",
                    marginRight: "-10px",
                  }}
                />
              </Button>
            </Grid>
            <Grid item md={2}>
              <Button
                onClick={() => {
                  setClearType("PARTNER");
                  setOpen(true);
                }}
                p={2}
                style={{
                  backgroundColor: "#1434A4",
                  color: "white",
                  height: "40px",
                  marginLeft: "0px",
                  marginTop: "15px",
                  width: "100%",
                  padding: "0px 25px 0px 25px",
                }}
              >
                <h2
                  style={{
                    fontSize: "18px",
                    color: "white",
                  }}
                >
                  CLEAR DUES
                </h2>
                <ChevronRightIcon
                  style={{
                    fontSize: "30px",
                    color: "white",
                    marginRight: "-10px",
                  }}
                />
              </Button>
            </Grid>
          </Grid>

          <Box style={{ marginTop: "15px", width: "100%" }}>
            {seeChart ? (
              <Grid container spacing={1.5}>
                <Grid item md={6}>
                  <Line
                    height={90}
                    width={200}
                    data={deliveryTotalChart.dataLine}
                    options={{ responsive: true }}
                  />
                </Grid>
                <Grid item md={6}>
                  <Line
                    height={90}
                    width={200}
                    data={deliveryOrdersChart.dataLine}
                    options={{ responsive: true }}
                  />
                </Grid>
              </Grid>
            ) : (
              ""
            )}
          </Box>
          <h1>
            Pending amount:{" "}
            {Math.round(
              (deliveryPaymentData["pending_payments"] + Number.EPSILON) * 100
            ) / 100}
          </h1>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell align="right">Date</TableCell>
                  <TableCell align="right">Order ID</TableCell>
                  <TableCell align="right">Distance</TableCell>
                  <TableCell align="right">Payment</TableCell>
                  <TableCell align="right">Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {deliveryPaymentData["payment_history"].map((order, index) => (
                  <TableRow
                    key={index + 1}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell align="right">{order["date"]}</TableCell>
                    <TableCell align="right">{order["order_id"]}</TableCell>
                    <TableCell align="right">
                      {Math.round((order["distance"] + Number.EPSILON) * 100) /
                        100}
                      {" KM"}
                    </TableCell>
                    <TableCell align="right">
                      {order["paid"] ? "PAID" : "NOT PAID"}
                    </TableCell>
                    <TableCell align="right">
                      {Math.round((order["payment"] + Number.EPSILON) * 100) /
                        100}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      );
    }
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.user.is_manager) {
        if (isLoading) {
          return (
            <Fragment>
              <Sidebar />
              <div
                style={{
                  maxWidth: "88vw",
                  marginTop: "45vh",
                  marginLeft: "8vw",
                  backgroundColor: "white",
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            </Fragment>
          );
        } else {
          return (
            <Fragment>
              <Sidebar
                tabs={
                  <Grid container spacing={1.5}>
                    <Grid item md={4}></Grid>
                    <Grid item md={4}>
                      {selectedPage == "VENDOR" ? (
                        <Button
                          fullWidth
                          value={"VENDOR"}
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#FFA500",
                            color: "white",
                            height: "30px",
                          }}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                            }}
                          >
                            VENDOR
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value={"VENDOR"}
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#FED8B1",
                            color: "white",
                            height: "30px",
                          }}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color: "white",
                            }}
                          >
                            VENDOR
                          </h3>
                        </Button>
                      )}
                    </Grid>
                    <Grid item md={4}>
                      {selectedPage == "DELIVERY" ? (
                        <Button
                          fullWidth
                          value="DELIVERY"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#FFA500",
                            color: "white",
                            height: "30px",
                          }}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                            }}
                          >
                            DELIVERY
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value="DELIVERY"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#FED8B1",
                            color: "white",
                            height: "30px",
                          }}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color: "white",
                            }}
                          >
                            DELIVERY
                          </h3>
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                }
              />

              {order.length !== 0 ? (
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={open2}
                  onClose={handleClose2}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={open2}>
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        backgroundColor: "white",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        padding: "20px",
                      }}
                    >
                      <div style={{ marginTop: "-25px", display: "flex" }}>
                        <div>
                          {getNumberOfPortions(
                            order["vendor_price"],
                            order["quantity"],
                            order["product_type"]
                          )}
                        </div>

                        <br></br>
                      </div>
                    </div>
                  </Fade>
                </Modal>
              ) : (
                <div></div>
              )}
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      backgroundColor: "white",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      padding: "20px",
                    }}
                  >
                    <div style={{ marginTop: "-25px" }}>
                      <h3>Are you sure?</h3>
                      <span>Clear all dues for</span>
                      <br></br>
                      <br></br>
                      <span>
                        <b>
                          {clearType == "VENDOR" ? vendorName : partnerName}
                        </b>
                      </span>
                      <br></br>
                      <br></br>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          onClick={() => clearAllDues()}
                          style={{ height: "30px" }}
                          variant="contained"
                        >
                          Yes
                        </Button>
                        <Button
                          style={{ height: "30px", marginLeft: "10px" }}
                          variant="contained"
                          onClick={() => setOpen(false)}
                        >
                          No
                        </Button>
                      </div>
                    </div>
                  </div>
                </Fade>
              </Modal>
              <div
                style={{
                  margin: "77px 10px 20px 90px",
                  backgroundColor: "white",
                }}
              >
                {getSelectedPageContent()}
              </div>
            </Fragment>
          );
        }
      } else {
        createAlert("ERROR", "Permission denied.", "Please Login as manager");
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Register;
