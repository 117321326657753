import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import moment from "moment";

import makeStyles from "@mui/styles/makeStyles";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { alertMessage } from "../actions/auth";
import { Bar } from "react-chartjs-2";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Chart } from "chart.js/auto";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Fab } from "@mui/material";
import Fade from "@mui/material/Fade";
import { refreshData } from "../actions/auth";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import {
  PRODUCTION,
  SERVER_API_ADDRESS,
  LOCAL_API_ADDRESS,
} from "../actions/auth";

function Orders() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPage, setSelectedPage] = useState(
    params.get("tab") ? params.get("tab") : "PENDING"
  );
  const [selectedOrderType, setSelectedOrderType] = useState("ORDERS");
  const [pendingOrders, setPendingOrders] = useState([]);
  const [deliveredOrders, setDeliveredOrders] = useState([]);
  const [cancelledOrders, setCancelledOrders] = useState([]);
  const [pendingExpressOrders, setPendingExpressOrders] = useState([]);
  const [deliveredExpressOrders, setDeliveredExpressOrders] = useState([]);
  const [cancelledExpressOrders, setCancelledExpressOrders] = useState([]);
  const [partnersList, setPartnersList] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState([]);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [order, setOrder] = useState({});
  const [orderCart, setOrderCart] = useState({});
  const [orderId, setOrderId] = useState("");
  const [partnerName, setPartnerName] = useState("");
  const [partnerId, setPartnerId] = useState("");
  const [orderType, setOrderType] = useState("");

  const handleOpen = (parnter, oId, oType) => {
    setOrderId(oId);
    setOrderType(oType);
    setPartnerName(parnter["partner_name"]);
    setPartnerId(parnter["partner_id"]);
    setOpen(true);
  };

  const handleOpen2 = (order) => {
    setOrder(order);
    setOrderCart(JSON.parse(order["order"]["cart"]));
    setOpen2(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  const handlePageSelect = (event) => {
    setSelectedPage(event.currentTarget.value);
  };

  const handleOrderTypeSelect = (event) => {
    setSelectedOrderType(event.currentTarget.value);
  };

  function getData() {
    axios
      .post(
        PRODUCTION
          ? SERVER_API_ADDRESS + "api/all_orders"
          : LOCAL_API_ADDRESS + "api/all_orders"
      )
      .then((res) => {
        axios
          .post(
            PRODUCTION
              ? SERVER_API_ADDRESS + "api/get_available_delivery_partners"
              : LOCAL_API_ADDRESS + "api/get_available_delivery_partners"
          )
          .then((res) => {
            var partners = res.data["data"];
            partners.unshift({
              partner_id: "none",
              partner_name: "Select --- ",
            });
            setPartnersList(partners);
            setSelectedPartner(res.data["data"][0]);
          });
        setPendingOrders(res.data["data"]["pending_orders"]);
        setDeliveredOrders(res.data["data"]["delivered_orders"]);
        setCancelledOrders(res.data["data"]["cancelled_orders"]);
        setPendingExpressOrders(res.data["data"]["pending_express_orders"]);
        setDeliveredExpressOrders(res.data["data"]["delivered_express_orders"]);
        setCancelledExpressOrders(res.data["data"]["cancelled_express_orders"]);
        setIsLoading(false);
      });
  }

  function getPartnersList() {
    var partners = partnersList.map((partner, index) => (
      <MenuItem value={partner}>{partner["partner_name"]}</MenuItem>
    ));
    return partners;
  }

  function assignPartner() {
    if (partnerId !== "none") {
      var body = {};
      const config = {
        headers: {
          Authorization: state.auth.token,
          "Content-Type": "application/json",
        },
      };

      body["order_id"] = orderId;
      body["partner_id"] = partnerId;
      body["order_type"] = orderType;
      body["time"] = moment(Date.now()).format("hh:mm A");
      body = JSON.stringify(body);
      axios
        .post(
          PRODUCTION
            ? SERVER_API_ADDRESS + "api/order_assign_delivery"
            : LOCAL_API_ADDRESS + "api/order_assign_delivery",
          body,
          config
        )
        .then((res) => {
          getData();
        });
      setOpen(false);
    }
  }

  function getStatusDiv(order) {
    if (order.approved && !order.prepared && !order.issued) {
      return (
        <div
          style={{
            display: "inline-block",
            borderRadius: "50px",
            backgroundColor: "#4a80f5",
            color: "white",
            padding: "0px 10px 0px 10px",
          }}
        >
          APPROVED
        </div>
      );
    }
    if (order.approved && order.prepared && !order.issued) {
      return (
        <div
          style={{
            display: "inline-block",
            borderRadius: "50px",
            backgroundColor: "#2ecc71 ",
            color: "white",
            padding: "0px 10px 0px 10px",
          }}
        >
          PREPARED
        </div>
      );
    }
    if (order.approved && order.prepared && order.issued) {
      return (
        <div
          style={{
            display: "inline-block",
            borderRadius: "50px",
            backgroundColor: "#e67e22 ",
            color: "white",
            padding: "0px 10px 0px 10px",
          }}
        >
          ISSUED
        </div>
      );
    }
  }

  function getPageContent() {
    if (isLoading) {
      return (
        <Fragment>
          <Sidebar />
          <div
            style={{
              maxWidth: "88vw",
              marginTop: "45vh",
              marginLeft: "8vw",
              backgroundColor: "white",
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        </Fragment>
      );
    } else if (selectedPage == "PENDING") {
      if (selectedOrderType == "ORDERS") {
        return (
          <div>
            <Grid container spacing={1.5} style={{ marginBottom: "15px" }}>
              <Grid item md={6}>
                {selectedOrderType == "ORDERS" ? (
                  <Button
                    fullWidth
                    value={"ORDERS"}
                    onClick={handleOrderTypeSelect}
                    p={2}
                    style={{
                      backgroundColor: "#1434A4",
                      color: "white",
                      height: "30px",
                    }}
                    borderRadius={6}
                  >
                    <h3
                      style={{
                        marginTop: "17px",
                        fontSize: "15px",
                      }}
                    >
                      ORDERS
                    </h3>
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    value={"ORDERS"}
                    onClick={handleOrderTypeSelect}
                    p={2}
                    style={{
                      backgroundColor: "#728FCE",
                      color: "white",
                      height: "30px",
                    }}
                    borderRadius={6}
                  >
                    <h3
                      style={{
                        marginTop: "17px",
                        fontSize: "15px",
                        color: "white",
                      }}
                    >
                      ORDERS
                    </h3>
                  </Button>
                )}
              </Grid>
              <Grid item md={6}>
                {selectedOrderType == "EXPRESS" ? (
                  <Button
                    fullWidth
                    value="EXPRESS"
                    onClick={handleOrderTypeSelect}
                    p={2}
                    style={{
                      backgroundColor: "#1434A4",
                      color: "white",
                      height: "30px",
                    }}
                    borderRadius={6}
                  >
                    <h3
                      style={{
                        marginTop: "17px",
                        fontSize: "15px",
                      }}
                    >
                      EXPRESS ORDERS
                    </h3>
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    value="EXPRESS"
                    onClick={handleOrderTypeSelect}
                    p={2}
                    style={{
                      backgroundColor: "#728FCE",
                      color: "white",
                      height: "30px",
                    }}
                    borderRadius={6}
                  >
                    <h3
                      style={{
                        marginTop: "17px",
                        fontSize: "15px",
                        color: "white",
                      }}
                    >
                      EXPRESS ORDERS
                    </h3>
                  </Button>
                )}
              </Grid>
            </Grid>

            {pendingOrders.length == 0 ? (
              <h1>No Pending Orders</h1>
            ) : (
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650, marginTop: "15px" }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell align="right">Time</TableCell>
                      <TableCell align="right">Order ID</TableCell>
                      <TableCell align="right">Customer</TableCell>
                      <TableCell align="right">Status</TableCell>
                      <TableCell align="right">Assign</TableCell>
                      <TableCell align="right">Category</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pendingOrders.map((order, index) => (
                      <TableRow
                        key={index + 1}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {index + 1} &nbsp;
                          <u
                            onClick={() => {
                              handleOpen2(order);
                            }}
                            style={{ color: "blue", cursor: "pointer" }}
                          >
                            (details)
                          </u>
                        </TableCell>
                        <TableCell align="right">
                          {moment(
                            order["created_at"].split(" ")[1].split(".")[0],
                            "HH:mm:ss"
                          ).format("hh:mm A")}
                        </TableCell>
                        <TableCell align="right">{order["order_id"]}</TableCell>
                        <TableCell align="right">
                          {order["full_name"]}
                        </TableCell>
                        <TableCell align="right">
                          {getStatusDiv(order)}
                        </TableCell>
                        <TableCell align="right">
                          {order.assigned ? (
                            <div
                              style={{
                                display: "inline-block",
                                borderRadius: "50px",
                                backgroundColor: "#e74c3c",
                                color: "white",
                                padding: "0px 10px 0px 10px",
                              }}
                            >
                              ASSIGNED
                            </div>
                          ) : (
                            <Select
                              value={selectedPartner}
                              onChange={(e) =>
                                handleOpen(
                                  e.target.value,
                                  order["order_id"],
                                  order["order_type"]
                                )
                              }
                              style={{
                                marginTop: "5px",
                                height: "35px",
                              }}
                              fullWidth
                              variant={"outlined"}
                            >
                              {getPartnersList()}
                            </Select>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {order["order_type"]}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        );
      } else {
        return (
          <div>
            <Grid container spacing={1.5} style={{ marginBottom: "15px" }}>
              <Grid item md={6}>
                {selectedOrderType == "ORDERS" ? (
                  <Button
                    fullWidth
                    value={"ORDERS"}
                    onClick={handleOrderTypeSelect}
                    p={2}
                    style={{
                      backgroundColor: "#1434A4",
                      color: "white",
                      height: "30px",
                    }}
                    borderRadius={6}
                  >
                    <h3
                      style={{
                        marginTop: "17px",
                        fontSize: "15px",
                      }}
                    >
                      ORDERS
                    </h3>
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    value={"ORDERS"}
                    onClick={handleOrderTypeSelect}
                    p={2}
                    style={{
                      backgroundColor: "#728FCE",
                      color: "white",
                      height: "30px",
                    }}
                    borderRadius={6}
                  >
                    <h3
                      style={{
                        marginTop: "17px",
                        fontSize: "15px",
                        color: "white",
                      }}
                    >
                      ORDERS
                    </h3>
                  </Button>
                )}
              </Grid>
              <Grid item md={6}>
                {selectedOrderType == "EXPRESS" ? (
                  <Button
                    fullWidth
                    value="EXPRESS"
                    onClick={handleOrderTypeSelect}
                    p={2}
                    style={{
                      backgroundColor: "#1434A4",
                      color: "white",
                      height: "30px",
                    }}
                    borderRadius={6}
                  >
                    <h3
                      style={{
                        marginTop: "17px",
                        fontSize: "15px",
                      }}
                    >
                      EXPRESS ORDERS
                    </h3>
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    value="EXPRESS"
                    onClick={handleOrderTypeSelect}
                    p={2}
                    style={{
                      backgroundColor: "#728FCE",
                      color: "white",
                      height: "30px",
                    }}
                    borderRadius={6}
                  >
                    <h3
                      style={{
                        marginTop: "17px",
                        fontSize: "15px",
                        color: "white",
                      }}
                    >
                      EXPRESS ORDERS
                    </h3>
                  </Button>
                )}
              </Grid>
            </Grid>

            {pendingExpressOrders.length == 0 ? (
              <h1>No Pending Orders</h1>
            ) : (
              <TableContainer
                component={Paper}
                style={{ marginBottom: "15px" }}
              >
                <Table
                  sx={{ minWidth: 650, marginTop: "15px" }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell align="right">Time</TableCell>
                      <TableCell align="right">Order ID</TableCell>
                      <TableCell align="right">Customer</TableCell>
                      <TableCell align="right">Status</TableCell>
                      <TableCell align="right">Assign</TableCell>
                      <TableCell align="right">Category</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pendingExpressOrders.map((order, index) => (
                      <TableRow
                        key={index + 1}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {index + 1} &nbsp;
                          <u
                            onClick={() => {
                              handleOpen2(order);
                            }}
                            style={{ color: "blue", cursor: "pointer" }}
                          >
                            (details)
                          </u>
                        </TableCell>
                        <TableCell align="right">
                          {moment(
                            order["created_at"].split(" ")[1].split(".")[0],
                            "HH:mm:ss"
                          ).format("hh:mm A")}{" "}
                        </TableCell>
                        <TableCell align="right">{order["order_id"]}</TableCell>
                        <TableCell align="right">
                          {order["full_name"]}
                        </TableCell>
                        <TableCell align="right">
                          {getStatusDiv(order)}
                        </TableCell>
                        <TableCell align="right">
                          {order.assigned ? (
                            <div
                              style={{
                                display: "inline-block",
                                borderRadius: "50px",
                                backgroundColor: "#e74c3c",
                                color: "white",
                                padding: "0px 10px 0px 10px",
                              }}
                            >
                              ASSIGNED
                            </div>
                          ) : (
                            <Select
                              value={selectedPartner}
                              onChange={(e) =>
                                handleOpen(
                                  e.target.value,
                                  order["order_id"],
                                  order["order_type"]
                                )
                              }
                              style={{
                                marginTop: "5px",
                                height: "35px",
                              }}
                              fullWidth
                              variant={"outlined"}
                            >
                              {getPartnersList()}
                            </Select>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {order["order_type"]}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        );
      }
    } else if (selectedPage == "DELIVERED") {
      if (selectedOrderType == "ORDERS") {
        return (
          <div>
            <Grid container spacing={1.5} style={{ marginBottom: "15px" }}>
              <Grid item md={6}>
                {selectedOrderType == "ORDERS" ? (
                  <Button
                    fullWidth
                    value={"ORDERS"}
                    onClick={handleOrderTypeSelect}
                    p={2}
                    style={{
                      backgroundColor: "#1434A4",
                      color: "white",
                      height: "30px",
                    }}
                    borderRadius={6}
                  >
                    <h3
                      style={{
                        marginTop: "17px",
                        fontSize: "15px",
                      }}
                    >
                      ORDERS
                    </h3>
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    value={"ORDERS"}
                    onClick={handleOrderTypeSelect}
                    p={2}
                    style={{
                      backgroundColor: "#728FCE",
                      color: "white",
                      height: "30px",
                    }}
                    borderRadius={6}
                  >
                    <h3
                      style={{
                        marginTop: "17px",
                        fontSize: "15px",
                        color: "white",
                      }}
                    >
                      ORDERS
                    </h3>
                  </Button>
                )}
              </Grid>
              <Grid item md={6}>
                {selectedOrderType == "EXPRESS" ? (
                  <Button
                    fullWidth
                    value="EXPRESS"
                    onClick={handleOrderTypeSelect}
                    p={2}
                    style={{
                      backgroundColor: "#1434A4",
                      color: "white",
                      height: "30px",
                    }}
                    borderRadius={6}
                  >
                    <h3
                      style={{
                        marginTop: "17px",
                        fontSize: "15px",
                      }}
                    >
                      EXPRESS ORDERS
                    </h3>
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    value="EXPRESS"
                    onClick={handleOrderTypeSelect}
                    p={2}
                    style={{
                      backgroundColor: "#728FCE",
                      color: "white",
                      height: "30px",
                    }}
                    borderRadius={6}
                  >
                    <h3
                      style={{
                        marginTop: "17px",
                        fontSize: "15px",
                        color: "white",
                      }}
                    >
                      EXPRESS ORDERS
                    </h3>
                  </Button>
                )}
              </Grid>
            </Grid>

            {deliveredOrders.length == 0 ? (
              <h1>No Delivered Orders</h1>
            ) : (
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650, marginTop: "15px" }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell align="right">Time</TableCell>
                      <TableCell align="right">Order ID</TableCell>
                      <TableCell align="right">Customer</TableCell>
                      <TableCell align="right">Status</TableCell>
                      <TableCell align="right">Assign</TableCell>
                      <TableCell align="right">Category</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {deliveredOrders.map((order, index) => (
                      <TableRow
                        key={index + 1}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {index + 1} &nbsp;
                          <u
                            onClick={() => {
                              handleOpen2(order);
                            }}
                            style={{ color: "blue", cursor: "pointer" }}
                          >
                            (details)
                          </u>
                        </TableCell>
                        <TableCell align="right">
                          {moment(
                            order["created_at"].split(" ")[1].split(".")[0],
                            "HH:mm:ss"
                          ).format("hh:mm A")}{" "}
                        </TableCell>
                        <TableCell align="right">{order["order_id"]}</TableCell>
                        <TableCell align="right">
                          {order["full_name"]}
                        </TableCell>
                        <TableCell align="right">
                          {getStatusDiv(order)}
                        </TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right">
                          {order["order_type"]}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        );
      } else {
        return (
          <div>
            <Grid container spacing={1.5} style={{ marginBottom: "15px" }}>
              <Grid item md={6}>
                {selectedOrderType == "ORDERS" ? (
                  <Button
                    fullWidth
                    value={"ORDERS"}
                    onClick={handleOrderTypeSelect}
                    p={2}
                    style={{
                      backgroundColor: "#1434A4",
                      color: "white",
                      height: "30px",
                    }}
                    borderRadius={6}
                  >
                    <h3
                      style={{
                        marginTop: "17px",
                        fontSize: "15px",
                      }}
                    >
                      ORDERS
                    </h3>
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    value={"ORDERS"}
                    onClick={handleOrderTypeSelect}
                    p={2}
                    style={{
                      backgroundColor: "#728FCE",
                      color: "white",
                      height: "30px",
                    }}
                    borderRadius={6}
                  >
                    <h3
                      style={{
                        marginTop: "17px",
                        fontSize: "15px",
                        color: "white",
                      }}
                    >
                      ORDERS
                    </h3>
                  </Button>
                )}
              </Grid>
              <Grid item md={6}>
                {selectedOrderType == "EXPRESS" ? (
                  <Button
                    fullWidth
                    value="EXPRESS"
                    onClick={handleOrderTypeSelect}
                    p={2}
                    style={{
                      backgroundColor: "#1434A4",
                      color: "white",
                      height: "30px",
                    }}
                    borderRadius={6}
                  >
                    <h3
                      style={{
                        marginTop: "17px",
                        fontSize: "15px",
                      }}
                    >
                      EXPRESS ORDERS
                    </h3>
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    value="EXPRESS"
                    onClick={handleOrderTypeSelect}
                    p={2}
                    style={{
                      backgroundColor: "#728FCE",
                      color: "white",
                      height: "30px",
                    }}
                    borderRadius={6}
                  >
                    <h3
                      style={{
                        marginTop: "17px",
                        fontSize: "15px",
                        color: "white",
                      }}
                    >
                      EXPRESS ORDERS
                    </h3>
                  </Button>
                )}
              </Grid>
            </Grid>

            {deliveredExpressOrders.length == 0 ? (
              <h1>No Delivered Orders</h1>
            ) : (
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650, marginTop: "15px" }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell align="right">Time</TableCell>
                      <TableCell align="right">Order ID</TableCell>
                      <TableCell align="right">Customer</TableCell>
                      <TableCell align="right">Status</TableCell>
                      <TableCell align="right">Assign</TableCell>
                      <TableCell align="right">Category</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {deliveredExpressOrders.map((order, index) => (
                      <TableRow
                        key={index + 1}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {index + 1} &nbsp;
                          <u
                            onClick={() => {
                              handleOpen2(order);
                            }}
                            style={{ color: "blue", cursor: "pointer" }}
                          >
                            (details)
                          </u>
                        </TableCell>
                        <TableCell align="right">
                          {moment(
                            order["created_at"].split(" ")[1].split(".")[0],
                            "HH:mm:ss"
                          ).format("hh:mm A")}{" "}
                        </TableCell>
                        <TableCell align="right">{order["order_id"]}</TableCell>
                        <TableCell align="right">
                          {order["full_name"]}
                        </TableCell>
                        <TableCell align="right">
                          {getStatusDiv(order)}
                        </TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right">
                          {order["order_type"]}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        );
      }
    } else if (selectedPage == "CANCELLED") {
      if (selectedOrderType == "ORDERS") {
        return (
          <div>
            <Grid container spacing={1.5} style={{ marginBottom: "15px" }}>
              <Grid item md={6}>
                {selectedOrderType == "ORDERS" ? (
                  <Button
                    fullWidth
                    value={"ORDERS"}
                    onClick={handleOrderTypeSelect}
                    p={2}
                    style={{
                      backgroundColor: "#1434A4",
                      color: "white",
                      height: "30px",
                    }}
                    borderRadius={6}
                  >
                    <h3
                      style={{
                        marginTop: "17px",
                        fontSize: "15px",
                      }}
                    >
                      ORDERS
                    </h3>
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    value={"ORDERS"}
                    onClick={handleOrderTypeSelect}
                    p={2}
                    style={{
                      backgroundColor: "#728FCE",
                      color: "white",
                      height: "30px",
                    }}
                    borderRadius={6}
                  >
                    <h3
                      style={{
                        marginTop: "17px",
                        fontSize: "15px",
                        color: "white",
                      }}
                    >
                      ORDERS
                    </h3>
                  </Button>
                )}
              </Grid>
              <Grid item md={6}>
                {selectedOrderType == "EXPRESS" ? (
                  <Button
                    fullWidth
                    value="EXPRESS"
                    onClick={handleOrderTypeSelect}
                    p={2}
                    style={{
                      backgroundColor: "#1434A4",
                      color: "white",
                      height: "30px",
                    }}
                    borderRadius={6}
                  >
                    <h3
                      style={{
                        marginTop: "17px",
                        fontSize: "15px",
                      }}
                    >
                      EXPRESS ORDERS
                    </h3>
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    value="EXPRESS"
                    onClick={handleOrderTypeSelect}
                    p={2}
                    style={{
                      backgroundColor: "#728FCE",
                      color: "white",
                      height: "30px",
                    }}
                    borderRadius={6}
                  >
                    <h3
                      style={{
                        marginTop: "17px",
                        fontSize: "15px",
                        color: "white",
                      }}
                    >
                      EXPRESS ORDERS
                    </h3>
                  </Button>
                )}
              </Grid>
            </Grid>

            {cancelledOrders.length == 0 ? (
              <h1>No Cancelled Orders</h1>
            ) : (
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650, marginTop: "15px" }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell align="right">Time</TableCell>
                      <TableCell align="right">Order ID</TableCell>
                      <TableCell align="right">Customer</TableCell>
                      <TableCell align="right">Status</TableCell>
                      <TableCell align="right">Assign</TableCell>
                      <TableCell align="right">Category</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cancelledOrders.map((order, index) => (
                      <TableRow
                        key={index + 1}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {index + 1} &nbsp;
                          <u
                            onClick={() => {
                              handleOpen2(order);
                            }}
                            style={{ color: "blue", cursor: "pointer" }}
                          >
                            (details)
                          </u>
                        </TableCell>
                        <TableCell align="right">
                          {moment(
                            order["created_at"].split(" ")[1].split(".")[0],
                            "HH:mm:ss"
                          ).format("hh:mm A")}{" "}
                        </TableCell>
                        <TableCell align="right">{order["order_id"]}</TableCell>
                        <TableCell align="right">
                          {order["full_name"]}
                        </TableCell>
                        <TableCell align="right">
                          {getStatusDiv(order)}
                        </TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right">
                          {order["order_type"]}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        );
      } else {
        return (
          <div>
            <Grid container spacing={1.5} style={{ marginBottom: "15px" }}>
              <Grid item md={6}>
                {selectedOrderType == "ORDERS" ? (
                  <Button
                    fullWidth
                    value={"ORDERS"}
                    onClick={handleOrderTypeSelect}
                    p={2}
                    style={{
                      backgroundColor: "#1434A4",
                      color: "white",
                      height: "30px",
                    }}
                    borderRadius={6}
                  >
                    <h3
                      style={{
                        marginTop: "17px",
                        fontSize: "15px",
                      }}
                    >
                      ORDERS
                    </h3>
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    value={"ORDERS"}
                    onClick={handleOrderTypeSelect}
                    p={2}
                    style={{
                      backgroundColor: "#728FCE",
                      color: "white",
                      height: "30px",
                    }}
                    borderRadius={6}
                  >
                    <h3
                      style={{
                        marginTop: "17px",
                        fontSize: "15px",
                        color: "white",
                      }}
                    >
                      ORDERS
                    </h3>
                  </Button>
                )}
              </Grid>
              <Grid item md={6}>
                {selectedOrderType == "EXPRESS" ? (
                  <Button
                    fullWidth
                    value="EXPRESS"
                    onClick={handleOrderTypeSelect}
                    p={2}
                    style={{
                      backgroundColor: "#1434A4",
                      color: "white",
                      height: "30px",
                    }}
                    borderRadius={6}
                  >
                    <h3
                      style={{
                        marginTop: "17px",
                        fontSize: "15px",
                      }}
                    >
                      EXPRESS ORDERS
                    </h3>
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    value="EXPRESS"
                    onClick={handleOrderTypeSelect}
                    p={2}
                    style={{
                      backgroundColor: "#728FCE",
                      color: "white",
                      height: "30px",
                    }}
                    borderRadius={6}
                  >
                    <h3
                      style={{
                        marginTop: "17px",
                        fontSize: "15px",
                        color: "white",
                      }}
                    >
                      EXPRESS ORDERS
                    </h3>
                  </Button>
                )}
              </Grid>
            </Grid>

            {cancelledExpressOrders.length == 0 ? (
              <h1>No Cancelled Orders</h1>
            ) : (
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650, marginTop: "15px" }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell align="right">Time</TableCell>
                      <TableCell align="right">Order ID</TableCell>
                      <TableCell align="right">Customer</TableCell>
                      <TableCell align="right">Status</TableCell>
                      <TableCell align="right">Assign</TableCell>
                      <TableCell align="right">Category</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cancelledExpressOrders.map((order, index) => (
                      <TableRow
                        key={index + 1}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {index + 1} &nbsp;
                          <u
                            onClick={() => {
                              handleOpen2(order);
                            }}
                            style={{ color: "blue", cursor: "pointer" }}
                          >
                            (details)
                          </u>
                        </TableCell>
                        <TableCell align="right">
                          {moment(
                            order["created_at"].split(" ")[1].split(".")[0],
                            "HH:mm:ss"
                          ).format("hh:mm A")}{" "}
                        </TableCell>
                        <TableCell align="right">{order["order_id"]}</TableCell>
                        <TableCell align="right">
                          {order["full_name"]}
                        </TableCell>
                        <TableCell align="right">
                          {getStatusDiv(order)}
                        </TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right">
                          {order["order_type"]}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        );
      }
    }
  }

  function getNumberOfPortions(
    productId,
    originalPrice,
    vendorPrice,
    quantity,
    type
  ) {
    if (type == 1) {
      return (
        <div>
          Price: {originalPrice} x {quantity}
          <br></br>
          Vendor Price:{vendorPrice} x {quantity}
        </div>
      );
    } else if (type == 2) {
      return (
        <div>
          {Object.keys(quantity).map((quant) => (
            <div>
              <div>
                Price: {quant} : {originalPrice[quant]} x {quantity[quant]}
                <br></br>
                Vendor Price: {quant} : {vendorPrice[quant]} x {quantity[quant]}
              </div>
            </div>
          ))}
        </div>
      );
    } else if (type == 3) {
      return (
        <div>
          {Object.keys(quantity).map((quant) => (
            <div>
              {quant !== "extras" ? (
                <div>
                  Price: {quant} : {originalPrice[quant]} x {quantity[quant]}
                  <br></br>
                  Vendor Price: {quant} : {vendorPrice[quant]} x{" "}
                  {quantity[quant]}
                </div>
              ) : (
                ""
              )}
            </div>
          ))}
        </div>
      );
    }
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      getData();
    }
  }, []);

  if (state.auth.refresh.includes("orders")) {
    getData();
    dispatch(refreshData("orders"));
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.user.is_manager) {
        if (isLoading) {
          return (
            <Fragment>
              <Sidebar />
              <div
                style={{
                  maxWidth: "88vw",
                  marginTop: "45vh",
                  marginLeft: "8vw",
                  backgroundColor: "white",
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            </Fragment>
          );
        } else {
          return (
            <Fragment>
              <Sidebar
                tabs={
                  <Grid container spacing={1.5}>
                    <Grid item md={4}>
                      {selectedPage == "PENDING" ? (
                        <Button
                          fullWidth
                          value={"PENDING"}
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#FFA500",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                            }}
                          >
                            PENDING
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value={"PENDING"}
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#FED8B1",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color: "white",
                            }}
                          >
                            PENDING
                          </h3>
                        </Button>
                      )}
                    </Grid>
                    <Grid item md={4}>
                      {selectedPage == "DELIVERED" ? (
                        <Button
                          fullWidth
                          value="DELIVERED"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#FFA500",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                            }}
                          >
                            DELIVERED
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value="DELIVERED"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#FED8B1",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color: "white",
                            }}
                          >
                            DELIVERED
                          </h3>
                        </Button>
                      )}
                    </Grid>
                    <Grid item md={4}>
                      {selectedPage == "CANCELLED" ? (
                        <Button
                          fullWidth
                          value="CANCELLED"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#FFA500",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                            }}
                          >
                            CANCELLED
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value="CANCELLED"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#FED8B1",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color: "white",
                            }}
                          >
                            CANCELLED
                          </h3>
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                }
              />
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      backgroundColor: "white",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      padding: "20px",
                    }}
                  >
                    <div style={{ marginTop: "-25px" }}>
                      <h3>Assign delivery to this order?</h3>
                      <span>This action cannot be reversed.</span>
                      <br></br>
                      <br></br>
                      <span>
                        Name:&nbsp;<b>{partnerName}</b>
                      </span>
                      <br></br>
                      <br></br>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          onClick={() => assignPartner()}
                          style={{ height: "30px" }}
                          variant="contained"
                        >
                          Yes
                        </Button>
                        <Button
                          style={{ height: "30px", marginLeft: "10px" }}
                          variant="contained"
                          onClick={() => setOpen(false)}
                        >
                          No
                        </Button>
                      </div>
                    </div>
                  </div>
                </Fade>
              </Modal>
              {order.length !== 0 ? (
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={open2}
                  onClose={handleClose2}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={open2}>
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        backgroundColor: "white",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        padding: "20px",
                      }}
                    >
                      {Object.keys(order).length != 0 ? (
                        <div style={{ marginTop: "-25px", display: "flex" }}>
                          <div>
                            <h3>Order Details</h3>
                            <br></br>
                            <b>Order ID:&nbsp;</b>
                            {order["order_id"]}
                            <br></br>
                            <b>Customer Name:&nbsp;</b> {order["full_name"]}
                            <br></br>
                            <b>Customer Phone:&nbsp;</b> {order["phone_number"]}
                            <br></br>
                            <b>Address:&nbsp;</b>{" "}
                            {order["address"]["apartment"] +
                              ", " +
                              order["address"]["address"] +
                              ", " +
                              order["address"]["landmark"]}
                            <br></br>
                            <b>Customer Total:&nbsp;</b>
                            {order["total_customer"]}
                            <br></br>
                            <b>Vendor Total:&nbsp;</b> {order["total_vendor"]}
                            <br></br>
                          </div>
                          {order["order_type"] == "EXPRESS" ? (
                            <div style={{ margin: "20px 0px 0px 50px" }}>
                              <b>Order:</b> <br /> {order["order"]}
                              <br /> <b>Instructions:</b> <br />
                              {order["instructions"]}
                              {order["attachment"].length == 0 ? (
                                ""
                              ) : (
                                <div>
                                  {" "}
                                  <br /> <b>Attachment:</b> <br />
                                  <a href={order["attachment"]}>
                                    {order["attachment"]}
                                  </a>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div style={{ margin: "20px 0px 0px 50px" }}>
                              {Object.keys(orderCart).map((productId) => (
                                <div>
                                  {!Array.isArray(orderCart[productId]) ? (
                                    <div>
                                      <b>
                                        {orderCart[productId]["product_name"]}
                                      </b>
                                      {" (" +
                                        orderCart[productId]["vendor_name"] +
                                        ")"}

                                      <div>
                                        {getNumberOfPortions(
                                          productId,
                                          orderCart[productId][
                                            "original_price"
                                          ],
                                          orderCart[productId]["vendor_price"],
                                          orderCart[productId]["quantity"],
                                          orderCart[productId]["product_type"]
                                        )}
                                      </div>

                                      <br></br>
                                    </div>
                                  ) : (
                                    <div>
                                      {orderCart[productId].map((product) => (
                                        <div>
                                          <b>{product["product_name"]}</b>
                                          {" (" + product["vendor_name"] + ")"}

                                          <div>
                                            {getNumberOfPortions(
                                              productId,
                                              product["original_price"],
                                              product["quantity"],
                                              product["product_type"],
                                              product["vendor_price"]
                                            )}
                                          </div>

                                          <br></br>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </Fade>
                </Modal>
              ) : (
                <div></div>
              )}
              <div
                style={{
                  margin: "80px 0px 20px 95px",
                  backgroundColor: "white",
                }}
              >
                {getPageContent()}
              </div>
            </Fragment>
          );
        }
      } else {
        createAlert("ERROR", "Permission denied.", "Please Login as manager");
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Orders;
