import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { alertMessage } from "../actions/auth";
import Paper from "@mui/material/Paper";
import Fab from "@mui/material/Fab";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import PublishIcon from "@mui/icons-material/Publish";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";

import {
  PRODUCTION,
  SERVER_API_ADDRESS,
  LOCAL_API_ADDRESS,
} from "../actions/auth";

function Support() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);

  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");

  function createSupportTicket() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,

        "Content-Type": "application/json",
      },
    };
    body["email"] = state.auth.user.email;
    body["subject"] = subject;
    body["description"] = description;

    body = JSON.stringify(body);

    axios
      .post(
        PRODUCTION
          ? SERVER_API_ADDRESS + "create_support_ticket"
          : LOCAL_API_ADDRESS + "create_support_ticket",
        body,
        config
      )
      .then((res) => {
        createAlert("SUCCESS", "Success.", "Support Ticket Created!");
      });
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.user.is_manager) {
        if (state.auth.expiry >= new Date()) {
          return (
            <Fragment>
              <Sidebar />

              <div
                style={{
                  margin: "100px 20px 20px 100px",
                  backgroundColor: "white",
                }}
              >
                <div
                  style={{
                    marginTop: "-18px",
                  }}
                >
                  <Box fullWidth style={{ display: "flex", width: "100%" }}>
                    <div style={{ marginTop: "-20px", width: "40%" }}>
                      <h2>
                        Email us at : <b>support@gogoz.in</b>
                      </h2>
                      <div style={{ fontSize: "20px", marginTop: "-20px" }}>
                        <h4>Contact us at : 9512131994</h4>
                      </div>
                      <h3>OR</h3>
                      <h3>Fill out this form to ask your queries</h3>
                      <span
                        style={{
                          color: "#FFA500",
                          fontFamily: "Lato-Regular",
                          fontSize: "14px",
                        }}
                      >
                        Class
                      </span>{" "}
                      <br></br>
                      <div>
                        <TextField
                          onChange={(e) => setSubject(e.target.value)}
                          style={{ marginTop: "2px" }}
                          fullWidth
                          size="small"
                          placeholder="Subject"
                          variant="outlined"
                        />
                        <br></br>
                        <br></br>
                        <span>Describe your problem here in details</span>
                        <br></br>
                        <TextField
                          style={{ marginTop: "2px" }}
                          multiline
                          rows={5}
                          fullWidth
                          variant="outlined"
                          placeholder="Description"
                        />
                        <br></br>
                        <br></br>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            style={{
                              fontSize: "15px",
                              height: "35px",
                              marginTop: "-1px",
                              backgroundColor: "#FFA500",
                            }}
                            onClick={() => createSupportTicket()}
                            variant="contained"
                          >
                            <h4>Create Ticket</h4>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Box>
                </div>
              </div>
            </Fragment>
          );
        } else {
          return <Navigate to="/expired" />;
        }
      } else {
        // createAlert("ERROR", "Permission denied.","Please Login as manager");

        return (
          <Fragment>
            <Navigate to="/login" />
          </Fragment>
        );
      }
    } else {
      return (
        <Fragment>
          <Navigate to="/login" />
        </Fragment>
      );
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Support;
