import { useState } from "react";
import Fade from "@mui/material/Fade";
import TextField from "@mui/material/TextField";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import { useSelector, useDispatch } from "react-redux";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { alertMessage } from "../actions/auth";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import { login } from "../actions/auth";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckCircle from "@mui/icons-material/CheckCircle";
import { Link, useNavigate } from "react-router-dom";
import {
  PRODUCTION,
  SERVER_API_ADDRESS,
  LOCAL_API_ADDRESS,
} from "../actions/auth";
const statesDistricts = {
  "Andhra Pradesh": [
    "Anantapur",
    "Chittoor",
    "East Godavari",
    "Guntur",
    "Krishna",
    "Kurnool",
    "Nellore",
    "Prakasam",
    "Srikakulam",
    "Visakhapatnam",
    "Vizianagaram",
    "West Godavari",
    "YSR Kadapa",
  ],
  "Arunachal Pradesh": [
    "Tawang",
    "West Kameng",
    "East Kameng",
    "Papum Pare",
    "Kurung Kumey",
    "Kra Daadi",
    "Lower Subansiri",
    "Upper Subansiri",
    "West Siang",
    "East Siang",
    "Siang",
    "Upper Siang",
    "Lower Siang",
    "Lower Dibang Valley",
    "Dibang Valley",
    "Anjaw",
    "Lohit",
    "Namsai",
    "Changlang",
    "Tirap",
    "Longding",
  ],
  Assam: [
    "Baksa",
    "Barpeta",
    "Biswanath",
    "Bongaigaon",
    "Cachar",
    "Charaideo",
    "Chirang",
    "Darrang",
    "Dhemaji",
    "Dhubri",
    "Dibrugarh",
    "Goalpara",
    "Golaghat",
    "Hailakandi",
    "Hojai",
    "Jorhat",
    "Kamrup Metropolitan",
    "Kamrup",
    "Karbi Anglong",
    "Karimganj",
    "Kokrajhar",
    "Lakhimpur",
    "Majuli",
    "Morigaon",
    "Nagaon",
    "Nalbari",
    "Dima Hasao",
    "Sivasagar",
    "Sonitpur",
    "South Salmara-Mankachar",
    "Tinsukia",
    "Udalguri",
    "West Karbi Anglong",
  ],
  Bihar: [
    "Araria",
    "Arwal",
    "Aurangabad",
    "Banka",
    "Begusarai",
    "Bhagalpur",
    "Bhojpur",
    "Buxar",
    "Darbhanga",
    "East Champaran (Motihari)",
    "Gaya",
    "Gopalganj",
    "Jamui",
    "Jehanabad",
    "Kaimur (Bhabua)",
    "Katihar",
    "Khagaria",
    "Kishanganj",
    "Lakhisarai",
    "Madhepura",
    "Madhubani",
    "Munger (Monghyr)",
    "Muzaffarpur",
    "Nalanda",
    "Nawada",
    "Patna",
    "Purnia (Purnea)",
    "Rohtas",
    "Saharsa",
    "Samastipur",
    "Saran",
    "Sheikhpura",
    "Sheohar",
    "Sitamarhi",
    "Siwan",
    "Supaul",
    "Vaishali",
    "West Champaran",
  ],
  Chandigarh: ["Chandigarh"],
  Chhattisgarh: [
    "Balod",
    "Baloda Bazar",
    "Balrampur",
    "Bastar",
    "Bemetara",
    "Bijapur",
    "Bilaspur",
    "Dantewada (South Bastar)",
    "Dhamtari",
    "Durg",
    "Gariyaband",
    "Janjgir-Champa",
    "Jashpur",
    "Kabirdham (Kawardha)",
    "Kanker (North Bastar)",
    "Kondagaon",
    "Korba",
    "Korea (Koriya)",
    "Mahasamund",
    "Mungeli",
    "Narayanpur",
    "Raigarh",
    "Raipur",
    "Rajnandgaon",
    "Sukma",
    "Surajpur  ",
    "Surguja",
  ],
  "Dadra and Nagar Haveli": ["Dadra & Nagar Haveli"],
  "Daman and Diu": ["Daman", "Diu"],
  Delhi: [
    "Central Delhi",
    "East Delhi",
    "New Delhi",
    "North Delhi",
    "North East  Delhi",
    "North West  Delhi",
    "Shahdara",
    "South Delhi",
    "South East Delhi",
    "South West  Delhi",
    "West Delhi",
  ],
  Goa: ["North Goa", "South Goa"],
  Gujarat: [
    "Ahmedabad",
    "Amreli",
    "Anand",
    "Aravalli",
    "Banaskantha (Palanpur)",
    "Bharuch",
    "Bhavnagar",
    "Botad",
    "Chhota Udepur",
    "Dahod",
    "Dangs (Ahwa)",
    "Devbhoomi Dwarka",
    "Gandhinagar",
    "Gir Somnath",
    "Jamnagar",
    "Junagadh",
    "Kachchh",
    "Kheda (Nadiad)",
    "Mahisagar",
    "Mehsana",
    "Morbi",
    "Narmada (Rajpipla)",
    "Navsari",
    "Panchmahal (Godhra)",
    "Patan",
    "Porbandar",
    "Rajkot",
    "Sabarkantha (Himmatnagar)",
    "Surat",
    "Surendranagar",
    "Tapi (Vyara)",
    "Vadodara",
    "Valsad",
  ],
  Haryana: [
    "Ambala",
    "Bhiwani",
    "Charkhi Dadri",
    "Faridabad",
    "Fatehabad",
    "Gurgaon",
    "Hisar",
    "Jhajjar",
    "Jind",
    "Kaithal",
    "Karnal",
    "Kurukshetra",
    "Mahendragarh",
    "Mewat",
    "Palwal",
    "Panchkula",
    "Panipat",
    "Rewari",
    "Rohtak",
    "Sirsa",
    "Sonipat",
    "Yamunanagar",
  ],
  "Himachal Pradesh": [
    "Bilaspur",
    "Chamba",
    "Hamirpur",
    "Kangra",
    "Kinnaur",
    "Kullu",
    "Lahaul &amp; Spiti",
    "Mandi",
    "Shimla",
    "Sirmaur (Sirmour)",
    "Solan",
    "Una",
  ],
  "Jammu and Kashmir": [
    "Anantnag",
    "Bandipore",
    "Baramulla",
    "Budgam",
    "Doda",
    "Ganderbal",
    "Jammu",
    "Kargil",
    "Kathua",
    "Kishtwar",
    "Kulgam",
    "Kupwara",
    "Leh",
    "Poonch",
    "Pulwama",
    "Rajouri",
    "Ramban",
    "Reasi",
    "Samba",
    "Shopian",
    "Srinagar",
    "Udhampur",
  ],
  Jharkhand: [
    "Bokaro",
    "Chatra",
    "Deoghar",
    "Dhanbad",
    "Dumka",
    "East Singhbhum",
    "Garhwa",
    "Giridih",
    "Godda",
    "Gumla",
    "Hazaribag",
    "Jamtara",
    "Khunti",
    "Koderma",
    "Latehar",
    "Lohardaga",
    "Pakur",
    "Palamu",
    "Ramgarh",
    "Ranchi",
    "Sahibganj",
    "Seraikela-Kharsawan",
    "Simdega",
    "West Singhbhum",
  ],
  Karnataka: [
    "Bagalkot",
    "Ballari (Bellary)",
    "Belagavi (Belgaum)",
    "Bengaluru (Bangalore) Rural",
    "Bengaluru (Bangalore) Urban",
    "Bidar",
    "Chamarajanagar",
    "Chikballapur",
    "Chikkamagaluru (Chikmagalur)",
    "Chitradurga",
    "Dakshina Kannada",
    "Davangere",
    "Dharwad",
    "Gadag",
    "Hassan",
    "Haveri",
    "Kalaburagi (Gulbarga)",
    "Kodagu",
    "Kolar",
    "Koppal",
    "Mandya",
    "Mysuru (Mysore)",
    "Raichur",
    "Ramanagara",
    "Shivamogga (Shimoga)",
    "Tumakuru (Tumkur)",
    "Udupi",
    "Uttara Kannada (Karwar)",
    "Vijayapura (Bijapur)",
    "Yadgir",
  ],
  Kerala: [
    "Alappuzha",
    "Ernakulam",
    "Idukki",
    "Kannur",
    "Kasaragod",
    "Kollam",
    "Kottayam",
    "Kozhikode",
    "Malappuram",
    "Palakkad",
    "Pathanamthitta",
    "Thiruvananthapuram",
    "Thrissur",
    "Wayanad",
  ],
  Lakshadweep: [
    "Agatti",
    "Amini",
    "Androth",
    "Bithra",
    "Chethlath",
    "Kavaratti",
    "Kadmath",
    "Kalpeni",
    "Kilthan",
    "Minicoy",
  ],
  "Madhya Pradesh": [
    "Agar Malwa",
    "Alirajpur",
    "Anuppur",
    "Ashoknagar",
    "Balaghat",
    "Barwani",
    "Betul",
    "Bhind",
    "Bhopal",
    "Burhanpur",
    "Chhatarpur",
    "Chhindwara",
    "Damoh",
    "Datia",
    "Dewas",
    "Dhar",
    "Dindori",
    "Guna",
    "Gwalior",
    "Harda",
    "Hoshangabad",
    "Indore",
    "Jabalpur",
    "Jhabua",
    "Katni",
    "Khandwa",
    "Khargone",
    "Mandla",
    "Mandsaur",
    "Morena",
    "Narsinghpur",
    "Neemuch",
    "Panna",
    "Raisen",
    "Rajgarh",
    "Ratlam",
    "Rewa",
    "Sagar",
    "Satna",
    "Sehore",
    "Seoni",
    "Shahdol",
    "Shajapur",
    "Sheopur",
    "Shivpuri",
    "Sidhi",
    "Singrauli",
    "Tikamgarh",
    "Ujjain",
    "Umaria",
    "Vidisha",
  ],
  Maharashtra: [
    "Ahmednagar",
    "Akola",
    "Amravati",
    "Aurangabad",
    "Beed",
    "Bhandara",
    "Buldhana",
    "Chandrapur",
    "Dhule",
    "Gadchiroli",
    "Gondia",
    "Hingoli",
    "Jalgaon",
    "Jalna",
    "Kolhapur",
    "Latur",
    "Mumbai City",
    "Mumbai Suburban",
    "Nagpur",
    "Nanded",
    "Nandurbar",
    "Nashik",
    "Osmanabad",
    "Palghar",
    "Parbhani",
    "Pune",
    "Raigad",
    "Ratnagiri",
    "Sangli",
    "Satara",
    "Sindhudurg",
    "Solapur",
    "Thane",
    "Wardha",
    "Washim",
    "Yavatmal",
  ],
  Manipur: [
    "Bishnupur",
    "Chandel",
    "Churachandpur",
    "Imphal East",
    "Imphal West",
    "Jiribam",
    "Kakching",
    "Kamjong",
    "Kangpokpi",
    "Noney",
    "Pherzawl",
    "Senapati",
    "Tamenglong",
    "Tengnoupal",
    "Thoubal",
    "Ukhrul",
  ],
  Meghalaya: [
    "East Garo Hills",
    "East Jaintia Hills",
    "East Khasi Hills",
    "North Garo Hills",
    "Ri Bhoi",
    "South Garo Hills",
    "South West Garo Hills ",
    "South West Khasi Hills",
    "West Garo Hills",
    "West Jaintia Hills",
    "West Khasi Hills",
  ],
  Mizoram: [
    "Aizawl",
    "Champhai",
    "Kolasib",
    "Lawngtlai",
    "Lunglei",
    "Mamit",
    "Saiha",
    "Serchhip",
  ],
  Nagaland: [
    "Dimapur",
    "Kiphire",
    "Kohima",
    "Longleng",
    "Mokokchung",
    "Mon",
    "Peren",
    "Phek",
    "Tuensang",
    "Wokha",
    "Zunheboto",
  ],
  Odisha: [
    "Angul",
    "Balangir",
    "Balasore",
    "Bargarh",
    "Bhadrak",
    "Boudh",
    "Cuttack",
    "Deogarh",
    "Dhenkanal",
    "Gajapati",
    "Ganjam",
    "Jagatsinghapur",
    "Jajpur",
    "Jharsuguda",
    "Kalahandi",
    "Kandhamal",
    "Kendrapara",
    "Kendujhar (Keonjhar)",
    "Khordha",
    "Koraput",
    "Malkangiri",
    "Mayurbhanj",
    "Nabarangpur",
    "Nayagarh",
    "Nuapada",
    "Puri",
    "Rayagada",
    "Sambalpur",
    "Sonepur",
    "Sundargarh",
  ],
  Puducherry: ["Karaikal", "Mahe", "Pondicherry", "Yanam"],
  Punjab: [
    "Amritsar",
    "Barnala",
    "Bathinda",
    "Faridkot",
    "Fatehgarh Sahib",
    "Fazilka",
    "Ferozepur",
    "Gurdaspur",
    "Hoshiarpur",
    "Jalandhar",
    "Kapurthala",
    "Ludhiana",
    "Mansa",
    "Moga",
    "Muktsar",
    "Nawanshahr (Shahid Bhagat Singh Nagar)",
    "Pathankot",
    "Patiala",
    "Rupnagar",
    "Sahibzada Ajit Singh Nagar (Mohali)",
    "Sangrur",
    "Tarn Taran",
  ],
  Rajasthan: [
    "Ajmer",
    "Alwar",
    "Banswara",
    "Baran",
    "Barmer",
    "Bharatpur",
    "Bhilwara",
    "Bikaner",
    "Bundi",
    "Chittorgarh",
    "Churu",
    "Dausa",
    "Dholpur",
    "Dungarpur",
    "Hanumangarh",
    "Jaipur",
    "Jaisalmer",
    "Jalore",
    "Jhalawar",
    "Jhunjhunu",
    "Jodhpur",
    "Karauli",
    "Kota",
    "Nagaur",
    "Pali",
    "Pratapgarh",
    "Rajsamand",
    "Sawai Madhopur",
    "Sikar",
    "Sirohi",
    "Sri Ganganagar",
    "Tonk",
    "Udaipur",
  ],
  Sikkim: ["East Sikkim", "North Sikkim", "South Sikkim", "West Sikkim"],
  "Tamil Nadu": [
    "Ariyalur",
    "Chennai",
    "Coimbatore",
    "Cuddalore",
    "Dharmapuri",
    "Dindigul",
    "Erode",
    "Kanchipuram",
    "Kanyakumari",
    "Karur",
    "Krishnagiri",
    "Madurai",
    "Nagapattinam",
    "Namakkal",
    "Nilgiris",
    "Perambalur",
    "Pudukkottai",
    "Ramanathapuram",
    "Salem",
    "Sivaganga",
    "Thanjavur",
    "Theni",
    "Thoothukudi (Tuticorin)",
    "Tiruchirappalli",
    "Tirunelveli",
    "Tiruppur",
    "Tiruvallur",
    "Tiruvannamalai",
    "Tiruvarur",
    "Vellore",
    "Viluppuram",
    "Virudhunagar",
  ],
  Telangana: [
    "Adilabad",
    "Bhadradri Kothagudem",
    "Hyderabad",
    "Jagtial",
    "Jangaon",
    "Jayashankar Bhoopalpally",
    "Jogulamba Gadwal",
    "Kamareddy",
    "Karimnagar",
    "Khammam",
    "Komaram Bheem Asifabad",
    "Mahabubabad",
    "Mahabubnagar",
    "Mancherial",
    "Medak",
    "Medchal",
    "Nagarkurnool",
    "Nalgonda",
    "Nirmal",
    "Nizamabad",
    "Peddapalli",
    "Rajanna Sircilla",
    "Rangareddy",
    "Sangareddy",
    "Siddipet",
    "Suryapet",
    "Vikarabad",
    "Wanaparthy",
    "Warangal (Rural)",
    "Warangal (Urban)",
    "Yadadri Bhuvanagiri",
  ],
  Tripura: [
    "Dhalai",
    "Gomati",
    "Khowai",
    "North Tripura",
    "Sepahijala",
    "South Tripura",
    "Unakoti",
    "West Tripura",
  ],
  Uttarakhand: [
    "Almora",
    "Bageshwar",
    "Chamoli",
    "Champawat",
    "Dehradun",
    "Haridwar",
    "Nainital",
    "Pauri Garhwal",
    "Pithoragarh",
    "Rudraprayag",
    "Tehri Garhwal",
    "Udham Singh Nagar",
    "Uttarkashi",
  ],
  "Uttar Pradesh": [
    "Agra",
    "Aligarh",
    "Allahabad",
    "Ambedkar Nagar",
    "Amethi (Chatrapati Sahuji Mahraj Nagar)",
    "Amroha (J.P. Nagar)",
    "Auraiya",
    "Azamgarh",
    "Baghpat",
    "Bahraich",
    "Ballia",
    "Balrampur",
    "Banda",
    "Barabanki",
    "Bareilly",
    "Basti",
    "Bhadohi",
    "Bijnor",
    "Budaun",
    "Bulandshahr",
    "Chandauli",
    "Chitrakoot",
    "Deoria",
    "Etah",
    "Etawah",
    "Faizabad",
    "Farrukhabad",
    "Fatehpur",
    "Firozabad",
    "Gautam Buddha Nagar",
    "Ghaziabad",
    "Ghazipur",
    "Gonda",
    "Gorakhpur",
    "Hamirpur",
    "Hapur (Panchsheel Nagar)",
    "Hardoi",
    "Hathras",
    "Jalaun",
    "Jaunpur",
    "Jhansi",
    "Kannauj",
    "Kanpur Dehat",
    "Kanpur Nagar",
    "Kanshiram Nagar (Kasganj)",
    "Kaushambi",
    "Kushinagar (Padrauna)",
    "Lakhimpur - Kheri",
    "Lalitpur",
    "Lucknow",
    "Maharajganj",
    "Mahoba",
    "Mainpuri",
    "Mathura",
    "Mau",
    "Meerut",
    "Mirzapur",
    "Moradabad",
    "Muzaffarnagar",
    "Pilibhit",
    "Pratapgarh",
    "RaeBareli",
    "Rampur",
    "Saharanpur",
    "Sambhal (Bhim Nagar)",
    "Sant Kabir Nagar",
    "Shahjahanpur",
    "Shamali (Prabuddh Nagar)",
    "Shravasti",
    "Siddharth Nagar",
    "Sitapur",
    "Sonbhadra",
    "Sultanpur",
    "Unnao",
    "Varanasi",
  ],
  "West Bengal": [
    "Alipurduar",
    "Bankura",
    "Birbhum",
    "Burdwan (Bardhaman)",
    "Cooch Behar",
    "Dakshin Dinajpur (South Dinajpur)",
    "Darjeeling",
    "Hooghly",
    "Howrah",
    "Jalpaiguri",
    "Kalimpong",
    "Kolkata",
    "Malda",
    "Murshidabad",
    "Nadia",
    "North 24 Parganas",
    "Paschim Medinipur (West Medinipur)",
    "Purba Medinipur (East Medinipur)",
    "Purulia",
    "South 24 Parganas",
    "Uttar Dinajpur (North Dinajpur)",
  ],
};

function Initialize() {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showSnack, setShowSnack] = useState(false);
  const [snack, setSnack] = useState("");
  const [page, setPage] = useState("HELLO");
  const [institutionName, setInstitutionName] = useState("");
  const [institutionId, setInstitutionId] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [selectedState, setSelectedState] = useState("Maharashtra");
  const [selectedCity, setSelectedCity] = useState("Mumbai City");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");

  const [classs, setClasss] = useState("");
  const [divisions, setDivisions] = useState("");
  const [numberOfYears, setNumberOfYears] = useState("");
  const [numberOfSemesters, setNumberOfSemesters] = useState("");
  const [currentYear, setCurrentYear] = useState("2022-2023");

  const [standardSystem, setStandardSystem] = useState(true);
  const [yearSystem, setYearSystem] = useState(false);
  const [clearText, setClearText] = useState("");

  const [firstname, setFirstname] = useState("");
  const [middlename, setMiddlename] = useState("");
  const [lastname, setLastname] = useState("");

  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }

  if (state.auth.user) {
    if (state.auth.user.initiated) {
      console.log("initiated");
      navigate("/dashboard");
    }
  }

  const years = [
    "First",
    "Second",
    "Third",
    "Fourth",
    "Fifth",
    "Sixth",
    "Seventh",
    "Eighth",
    "Ninth",
    "Tenth",
  ];

  function getClassStructure() {
    if (standardSystem) {
      return classs + " " + numberOfYears + " " + divisions.split(",")[0];
    } else {
      return "First Year " + divisions.split(",")[0];
    }
  }

  if (state.auth.alert) {
    setSnack({
      title: state.auth.alert.title,
      message: state.auth.alert.message,
      type: state.auth.alert.type,
    });
    setShowSnack(true);
    dispatch({ type: "REMOVE_ALERT" });
  }

  function getStateList() {
    var states = Object.keys(statesDistricts).map((stat, index) => (
      <MenuItem value={stat}>{stat}</MenuItem>
    ));
    return states;
  }

  function getCityList() {
    var cites = statesDistricts[selectedState].map((city, index) => (
      <MenuItem value={city}>{city}</MenuItem>
    ));
    return cites;
  }

  function getClassStructureJSON() {
    var classStructureJSON = {};
    if (numberOfYears < 1 || numberOfSemesters < 1) {
      createAlert(
        "ERROR",
        "Error.",
        "Minimum Number of years and semesters is 1."
      );
    }
    var semesterDict = [];
    var divisionDict = {};
    var classDict = {};
    if (standardSystem) {
      for (var i = 1; i <= numberOfYears; i++) {
        for (var j = 0; j < divisions.split(",").length; j++) {
          for (var k = 1; k <= numberOfSemesters; k++) {
            divisionDict["Semester " + k] = semesterDict;
          }
          classDict[divisions.split(",")[j].trim()] = divisionDict;
        }
        classStructureJSON[classs + " " + i] = classDict;
      }
    } else {
      for (var i = 1; i <= numberOfYears; i++) {
        for (var j = 0; j < divisions.split(",").length; j++) {
          for (var k = 1; k <= numberOfSemesters; k++) {
            divisionDict["Semester " + k] = semesterDict;
          }
          classDict[divisions.split(",")[j].trim()] = divisionDict;
        }
        classStructureJSON[years[i - 1] + " Year"] = classDict;
      }
    }
    return classStructureJSON;
  }

  function isNumeric(str) {
    return !isNaN(str) && !isNaN(parseFloat(str));
  }

  function submitClassStructure() {
    if (
      currentYear.length == 0 ||
      classs.length == 0 ||
      divisions.length == 0 ||
      numberOfSemesters.length == 0 ||
      numberOfYears.length == 0
    ) {
      createAlert("ERROR", "Error", "Please fill all the fields.");
    } else {
      if (isNumeric(numberOfSemesters) && isNumeric(numberOfYears)) {
        var body = {};
        const config = {
          headers: {
            Authorization: state.auth.token,

            "Content-Type": "application/json",
          },
        };
        body["class_list"] = getClassStructureJSON();
        body["institution_id"] = institutionId;
        body["current_year"] = currentYear;

        body["email"] = email;
        body = JSON.stringify(body);
        if (Object.keys(getClassStructure()).length == 0) {
          createAlert("ERROR", "Error", "Class structure not defined.");
        } else {
          axios
            .post(
              PRODUCTION
                ? SERVER_API_ADDRESS + "initialize_class_list"
                : LOCAL_API_ADDRESS + "initialize_class_list",
              body,
              config
            )
            .then((res) => {
              setPage("PROFILE");
            });
        }
      } else {
        createAlert(
          "ERROR",
          "Error",
          "Enter a valid number for Years and Semesters."
        );
      }
    }
  }

  function submitProfile() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,

        "Content-Type": "application/json",
      },
    };

    if (firstname.length == 0 || lastname.length == 0) {
      createAlert("ERROR", "Error", "Please fill all the fields.");
    } else {
      body["email"] = email;
      body["firstname"] = firstname;
      body["middlename"] = middlename;
      body["lastname"] = lastname;

      axios
        .post(
          PRODUCTION
            ? SERVER_API_ADDRESS + "initialize_manager_profile"
            : LOCAL_API_ADDRESS + "initialize_manager_profile",
          body,
          config
        )
        .then((res) => {
          setPage("FINISHED");
        })
        .catch((err) => {
          createAlert("ERROR", "Error", err.response.data);
        });
    }
  }

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/
    );
  };

  function registerUser() {
    if (institutionId.length == 0) {
      createAlert("ERROR", "Error", "Enter Institution ID to continue.");
    } else if (!validateEmail(email)) {
      createAlert("ERROR", "Error", "Enter valid Email ID");
    } else {
      if (password.length > 7 && password == password2) {
        var body = {};
        const config = {
          headers: {
            Authorization: state.auth.token,

            "Content-Type": "application/json",
          },
        };
        body["password"] = password;
        body["email"] = email;
        body["institution_id"] = institutionId;
        body = JSON.stringify(body);
        axios
          .post(
            PRODUCTION
              ? SERVER_API_ADDRESS + "register_manager"
              : LOCAL_API_ADDRESS + "register_manager",
            body,
            config
          )
          .then((res) => {
            dispatch(login(email, password));
            setPage("DETAILS");
          })
          .catch((err) => {
            createAlert("ERROR", "Error", err.response.data);
          });
      } else {
        createAlert(
          "ERROR",
          "Password Error.",
          "Password Mismatch or less than 8 characters"
        );
      }
    }
  }

  function registerInstitution() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,

        "Content-Type": "application/json",
      },
    };
    if (institutionName.length == 0) {
      createAlert("ERROR", "Error", "Enter a name for your institution.");
    } else {
      body["institution_name"] = institutionName;
      axios
        .post(
          PRODUCTION
            ? SERVER_API_ADDRESS + "register_institution"
            : LOCAL_API_ADDRESS + "register_institution",
          body,
          config
        )
        .then((res) => {
          setInstitutionId(res.data);
          setPage("REGISTER");
        })
        .catch((err) => {
          createAlert("ERROR", "Error", err.response.data);
        });
    }
  }

  function submitDetails() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,

        "Content-Type": "application/json",
      },
    };
    if (institutionName.length == 0) {
      createAlert("ERROR", "Error", "Enter a name for your institution.");
    } else if (pincode.length > 7) {
      createAlert("ERROR", "Error", "Enter valid pincode");
    } else if (phoneNumber.length > 15) {
      createAlert("ERROR", "Error", "Enter valid phone number");
    } else {
      body["institution_name"] = institutionName;
      body["phone_number"] = phoneNumber;
      body["pincode"] = pincode;
      body["city"] = selectedCity;
      body["state"] = selectedState;
      body["institution_address"] = address;
      body["institution_id"] = institutionId;

      axios
        .post(
          PRODUCTION
            ? SERVER_API_ADDRESS + "initialize_institution_profile"
            : LOCAL_API_ADDRESS + "initialize_institution_profile",
          body,
          config
        )
        .then((res) => {
          setPage("CLASSES");
        })
        .catch((err) => {
          createAlert("ERROR", "Error", err.response.data);
        });
    }
  }

  function getPage() {
    switch (page) {
      case "HELLO":
        return (
          <div style={{ width: "100%" }}>
            <h1 style={{ marginTop: "45px" }}>Welcome to Elemzy!</h1>
            <h3 style={{ marginTop: "-15px" }}>Let's get started.</h3>
            <br></br>
            <p>What is the name of your institution?</p>
            <div style={{ width: "50%" }}>
              <TextField
                fullWidth
                style={{ marginTop: "2px" }}
                value={institutionName}
                size="small"
                onChange={(e) => setInstitutionName(e.target.value)}
                placeholder={"Institution Name"}
                variant="outlined"
              />
            </div>
            <br></br>
            <br></br>
            <Link
              style={{ textDecoration: "none", textAlign: "right" }}
              to="/login"
            >
              Already registered? Login here.
            </Link>
            <Button
              style={{
                width: "100px",
                backgroundColor: "#FFA500",
                color: "white",
                fontFamily: "Bahnschrift",
                height: "30px",
                position: "absolute",
                bottom: 30,
                right: 30,
              }}
              onClick={() => registerInstitution()}
            >
              Next
            </Button>
          </div>
        );

      case "REGISTER":
        return (
          <div style={{ width: "100%", animation: "fadeInAnimation ease 1s" }}>
            <h1 style={{ marginTop: "45px" }}>Registration</h1>
            <h3 style={{ marginTop: "-15px", marginBottom: "25px" }}>
              You are required to create an account to use our services.
            </h3>
            <div style={{ width: "50%" }}>
              <p>Email</p>
              <TextField
                style={{ marginTop: "-10px" }}
                value={email}
                fullWidth
                size="small"
                onChange={(e) => setEmail(e.target.value)}
                placeholder={"E-mail"}
                variant="outlined"
              />
              <p>Password</p>
              <TextField
                style={{ marginTop: "-10px" }}
                value={password}
                fullWidth
                type={clearText ? "text" : "password"}
                size="small"
                onChange={(e) => setPassword(e.target.value)}
                placeholder={"Password"}
                variant="outlined"
                autoComplete="password"
                InputProps={{
                  endAdornment: (
                    <IconButton
                      size="small"
                      style={{ marginRight: "-8px", paddingLeft: "10px" }}
                      onClick={() => setClearText(!clearText)}
                    >
                      {clearText ? (
                        <VisibilityOffIcon style={{ color: "#FFA500" }} />
                      ) : (
                        <VisibilityIcon style={{ color: "#FFA500" }} />
                      )}
                    </IconButton>
                  ),
                }}
              />
              <p>Confirm Password</p>
              <TextField
                style={{ marginTop: "-10px" }}
                value={password2}
                fullWidth
                type={clearText ? "text" : "password"}
                size="small"
                onChange={(e) => setPassword2(e.target.value)}
                placeholder={"Confirm Password"}
                variant="outlined"
              />
            </div>
            <Button
              style={{
                width: "100px",

                backgroundColor: "#FFA500",
                color: "white",
                fontFamily: "Bahnschrift",
                height: "30px",
                position: "absolute",
                bottom: 30,
                right: 150,
              }}
              onClick={() => setPage("HELLO")}
            >
              Previous
            </Button>
            <Button
              style={{
                width: "100px",
                backgroundColor: "#FFA500",
                color: "white",
                fontFamily: "Bahnschrift",
                height: "30px",
                position: "absolute",
                bottom: 30,
                right: 30,
              }}
              onClick={() => registerUser()}
            >
              Next
            </Button>
          </div>
        );
      case "DETAILS":
        return (
          <div style={{ width: "100%", animation: "fadeInAnimation ease 1s" }}>
            <h1 style={{ marginTop: "45px" }}>Additional Details</h1>
            <h3 style={{ marginTop: "-15px" }}>
              We would like to know some additional details for your
              institutions page.
            </h3>
            <h5 style={{ marginTop: "-15px" }}>You can skip this for later.</h5>
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%", marginRight: "25px" }}>
                <p>Phone Number</p>
                <TextField
                  style={{ marginTop: "-10px" }}
                  value={phoneNumber}
                  fullWidth
                  size="small"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder={"Phone Number"}
                  variant="outlined"
                  required
                />
                <p>Institution Address</p>
                <TextField
                  style={{ marginTop: "-10px" }}
                  value={address}
                  fullWidth
                  size="small"
                  onChange={(e) => setAddress(e.target.value)}
                  placeholder={"Institution Address"}
                  variant="outlined"
                  required
                />
                <p>Pincode</p>
                <TextField
                  style={{ marginTop: "-10px" }}
                  value={pincode}
                  fullWidth
                  size="small"
                  onChange={(e) => setPincode(e.target.value)}
                  placeholder={"Pincode"}
                  variant="outlined"
                  required
                />
              </div>
              <div style={{ width: "50%", marginTop: "-3px" }}>
                <p>State</p>
                <Select
                  value={selectedState}
                  onChange={(e) => {
                    setSelectedState(e.target.value);
                    setSelectedCity(statesDistricts[e.target.value][0]);
                  }}
                  style={{ marginTop: "-10px", height: "40px" }}
                  fullWidth
                  variant={"outlined"}
                >
                  {getStateList()}
                </Select>
                <p>City</p>
                <Select
                  value={selectedCity}
                  onChange={(e) => setSelectedCity(e.target.value)}
                  style={{ marginTop: "-15px", height: "40px" }}
                  fullWidth
                  variant={"outlined"}
                >
                  {getCityList()}
                </Select>
              </div>
            </div>
            <Button
              style={{
                width: "100px",
                fontFamily: "Bahnschrift",
                height: "30px",
                position: "absolute",
                bottom: 30,
                right: 150,
                border: "1px solid #FFA500",
              }}
              onClick={() => setPage("CLASSES")}
            >
              Skip
            </Button>{" "}
            <Button
              style={{
                width: "100px",
                backgroundColor: "#FFA500",
                color: "white",
                fontFamily: "Bahnschrift",
                height: "30px",
                position: "absolute",
                bottom: 30,
                right: 30,
              }}
              onClick={() => submitDetails()}
            >
              Next
            </Button>{" "}
          </div>
        );
      case "CLASSES":
        return (
          <div style={{ width: "100%", animation: "fadeInAnimation ease 1s" }}>
            <h2 style={{ marginTop: "45px" }}>
              Great! Now that we are done with basic setup,<br></br> let's get
              started with your institution's class structure.
            </h2>
            <h4 style={{ marginTop: "15px", marginBottom: "35px" }}>
              What type of students does your institution have?
            </h4>
            <div style={{ display: "flex" }}>
              <h4 style={{ marginTop: "0px" }}>Students below 12th grade</h4>
              <Checkbox
                disableRipple={true}
                labelStyle={{ color: "#FFA500" }}
                iconStyle={{ fill: "white" }}
                checked={standardSystem}
                onChange={(e) => {
                  setStandardSystem(e.target.checked);
                  setYearSystem(!e.target.checked);
                  if (e.target.checked) {
                    setClasss("Standard");
                    setNumberOfYears("10");
                    setDivisions("A, B, C");
                  } else {
                    setNumberOfYears("4");
                    setDivisions("Mechanical A, Computer Science B");
                  }
                }}
                value={1}
                style={{
                  transform: "scale(1.2)",
                  color: "#FFA500",
                  marginTop: "-24px",
                }}
              />
              <h4 style={{ marginTop: "0px", marginLeft: "20px" }}>
                Students above 12th grade (Diploma or Degree)
              </h4>
              <Checkbox
                disableRipple={true}
                labelStyle={{ color: "#FFA500" }}
                iconStyle={{ fill: "white" }}
                checked={yearSystem}
                onChange={(e) => {
                  setStandardSystem(!e.target.checked);
                  setYearSystem(e.target.checked);

                  if (!e.target.checked) {
                    setClasss("Standard");
                    setNumberOfYears("10");
                    setDivisions("A, B, C");
                  } else {
                    setNumberOfYears("4");
                    setDivisions("Mechanical A, Computer Science B");
                  }
                }}
                value={1}
                style={{
                  transform: "scale(1.2)",
                  color: "#FFA500",
                  marginTop: "-24px",
                }}
              />
              <div
                style={{ width: "39%", marginLeft: "23px", marginTop: "-60px" }}
              >
                <h3 style={{ marginBottom: "10px" }}>Current Academic Year</h3>
                <TextField
                  fullWidth
                  value={currentYear}
                  size="small"
                  onChange={(e) => setCurrentYear(e.target.value)}
                  placeholder={"Current Academic year"}
                  variant="outlined"
                />
              </div>
            </div>
            {standardSystem ? (
              <div style={{ display: "flex" }}>
                <div style={{ width: "60%" }}>
                  <h3 style={{ marginTop: "25px" }}>
                    What do you call your classes?
                  </h3>
                  <TextField
                    style={{ marginTop: "-15px" }}
                    value={classs}
                    fullWidth
                    size="small"
                    onChange={(e) => setClasss(e.target.value)}
                    placeholder={"E.g. Class, Grade, Standard etc."}
                    variant="outlined"
                  />
                </div>
                <div style={{ width: "40%", marginLeft: "25px" }}>
                  <h3 style={{ marginTop: "25px" }}>Number of Years</h3>
                  <TextField
                    style={{ marginTop: "-15px" }}
                    value={numberOfYears}
                    fullWidth
                    keyboardType="numeric"
                    size="small"
                    onChange={(e) => setNumberOfYears(e.target.value)}
                    placeholder={"Number of years"}
                    variant="outlined"
                  />
                </div>
              </div>
            ) : (
              <div style={{ display: "flex", marginTop: "10px" }}>
                <div style={{ width: "60%" }}>
                  <h3 style={{ marginTop: "15px" }}>Number of Years</h3>
                  <TextField
                    style={{ marginTop: "-15px" }}
                    value={numberOfYears}
                    fullWidth
                    keyboardType="numeric"
                    size="small"
                    onChange={(e) => setNumberOfYears(e.target.value)}
                    placeholder={"Number of years"}
                    variant="outlined"
                  />
                </div>
                <div style={{ width: "40%", marginLeft: "25px" }}></div>
              </div>
            )}
            <div style={{ display: "flex" }}>
              <div style={{ width: "60%" }}>
                <div style={{ display: "flex" }}>
                  <h3 style={{ marginTop: "25px" }}>
                    What are the divisions for each class? Enter values
                    seperated by commas.
                  </h3>
                  <Tooltip
                    arrow
                    title="E.g. A, B, C or Computer Science A, Computer Science B, Mechanical A, Mechanical B "
                    placement="right"
                  >
                    <LiveHelpIcon
                      style={{
                        color: "#FFA500",
                        marginTop: "20px",
                        marginLeft: "10px",
                      }}
                    />
                  </Tooltip>
                </div>
                <TextField
                  style={{ marginTop: "-15px" }}
                  value={divisions}
                  fullWidth
                  rows={5}
                  size="small"
                  onChange={(e) => setDivisions(e.target.value)}
                  placeholder={
                    "E.g. A, B, C or Computer Science A, Computer Science B, Mechanical A, Mechanical B "
                  }
                  variant="outlined"
                />
              </div>
              <div style={{ width: "40%", marginLeft: "25px" }}>
                <h3 style={{ marginTop: "25px" }}>
                  Number of Semesters per year
                </h3>
                <TextField
                  style={{ marginTop: "-15px" }}
                  value={numberOfSemesters}
                  fullWidth
                  keyboardType="numeric"
                  size="small"
                  onChange={(e) => setNumberOfSemesters(e.target.value)}
                  placeholder={"Number of Semesters or terms for each year"}
                  variant="outlined"
                />
              </div>
            </div>
            <br></br>
            <h1
              style={{
                position: "absolute",
                top: 70,
                right: 50,
                textAlign: "right",
                fontFamily: "Lato-bold",
                color: "#FFA500",
              }}
            >
              {getClassStructure()}
            </h1>
            <h5
              style={{
                position: "absolute",
                top: 120,
                right: 50,
                textAlign: "right",
              }}
            >
              This is how your class name will look.
            </h5>
            <Button
              style={{
                width: "100px",

                backgroundColor: "#FFA500",
                color: "white",
                fontFamily: "Bahnschrift",
                height: "30px",
                position: "absolute",
                bottom: 30,
                right: 150,
              }}
              onClick={() => setPage("DETAILS")}
            >
              Previous
            </Button>
            <Button
              style={{
                width: "100px",
                backgroundColor: "#FFA500",
                color: "white",
                fontFamily: "Bahnschrift",
                height: "30px",
                position: "absolute",
                bottom: 30,
                right: 30,
              }}
              onClick={() => {
                submitClassStructure();
              }}
            >
              Next
            </Button>{" "}
          </div>
        );
      case "PROFILE":
        return (
          <div style={{ width: "100%", animation: "fadeInAnimation ease 1s" }}>
            <h3 style={{ marginTop: "45px" }}>
              We would like to know some additional details for your profile
              page.
            </h3>
            <div style={{ display: "flex" }}>
              <div style={{ width: "33.33%", marginRight: "25px" }}>
                {" "}
                <p>First Name</p>
                <TextField
                  style={{ marginTop: "-10px" }}
                  value={firstname}
                  fullWidth
                  size="small"
                  onChange={(e) => setFirstname(e.target.value)}
                  placeholder={"First Name"}
                  variant="outlined"
                  required
                />
              </div>
              <div style={{ width: "33.33%", marginRight: "25px" }}>
                {" "}
                <p>Middle Name</p>
                <TextField
                  style={{ marginTop: "-10px" }}
                  value={middlename}
                  fullWidth
                  size="small"
                  onChange={(e) => setMiddlename(e.target.value)}
                  placeholder={"Middle Name (optional)"}
                  variant="outlined"
                  required
                />
              </div>
              <div style={{ width: "33.33%", marginRight: "25px" }}>
                {" "}
                <p>Last Name</p>
                <TextField
                  style={{ marginTop: "-10px" }}
                  value={lastname}
                  fullWidth
                  size="small"
                  onChange={(e) => setLastname(e.target.value)}
                  placeholder={"Last Name"}
                  variant="outlined"
                  required
                />
              </div>
            </div>
            <Button
              style={{
                width: "100px",
                backgroundColor: "#FFA500",
                color: "white",
                fontFamily: "Bahnschrift",
                height: "30px",
                position: "absolute",
                bottom: 30,
                right: 30,
              }}
              onClick={() => submitProfile()}
            >
              Next
            </Button>{" "}
          </div>
        );
      case "FINISHED":
        return (
          <div
            style={{
              width: "100%",
              animation: "fadeInAnimation ease 1s",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h1 style={{ marginTop: "145px", textAlign: "center" }}>
              Finished!
            </h1>
            <CheckCircle
              style={{
                fontSize: "100px",
                color: "#5cb85c",
                textAlign: "center",
              }}
            />
            <h1>INSTITUTION ID : {institutionId}</h1>

            <Button
              style={{
                width: "200px",
                backgroundColor: "#FFA500",
                color: "white",
                fontFamily: "Bahnschrift",
                height: "30px",
                marginTop: "20px",
              }}
              onClick={() => navigate("/dashboard", { replace: true })}
            >
              Go to Dashboard
            </Button>
          </div>
        );

      default:
        setPage("HELLO");
        return <h1>Shite</h1>;
    }
  }

  function Copyright() {
    return (
      <Typography variant="body2" color="black" align="center">
        {"Copyright © "}
        Elemzy {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  }

  return (
    <div style={{ width: "100%" }}>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={6000}
        open={showSnack}
        onClose={() => setShowSnack(false)}
      >
        <Card style={{ minWidth: "350px", padding: "10px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex" }}>
              {snack["type"] == "SUCCESS" ? (
                <CheckCircleIcon
                  style={{ color: "#2ca25f", fontSize: "44px" }}
                />
              ) : snack["type"] == "MESSAGE" ? (
                <ErrorIcon style={{ color: "#FFA500", fontSize: "44px" }} />
              ) : (
                <ErrorIcon style={{ color: "#ff3333", fontSize: "44px" }} />
              )}
              <div style={{ marginLeft: "10px" }}>
                <b style={{ fontSize: "15px" }}>{snack["title"]}</b>
                <br></br>
                <span>{snack["message"]}</span>
              </div>
            </div>
            <IconButton size="small">
              <CloseIcon
                onClick={() => setShowSnack(false)}
                style={{ color: "#FFA500" }}
              />
            </IconButton>
          </div>
        </Card>
      </Snackbar>
      <AppBar
        position="fixed"
        elevation={1}
        style={{
          backgroundColor: "white",
          height: "50px",
        }}
      >
        <Toolbar
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <img
            style={{ maxHeight: "40px", marginTop: "-15px" }}
            src={require("../img/appbar.png")}
          />
          <a
            style={{ textDecoration: "none" }}
            href={PRODUCTION ? SERVER_API_ADDRESS + "" : LOCAL_API_ADDRESS + ""}
          >
            <Button
              style={{
                backgroundColor: "white",
                color: "black",
                fontSize: "16px",
                marginTop: "3px",
                height: "30px",
                marginTop: "-20px",
              }}
            >
              <ChevronLeftIcon style={{ fontSize: "35px" }} /> Back to Home
            </Button>
          </a>
        </Toolbar>
      </AppBar>
      <div style={{ padding: "50px" }}>{getPage()}</div>
    </div>
  );
}
export default Initialize;
