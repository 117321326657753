import { useState, Fragment, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { alertMessage } from "../actions/auth";
import Typography from "@mui/material/Typography";
import { login } from "../actions/auth";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Snackbar from "@mui/material/Snackbar";
import Card from "@mui/material/Card";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import IconButton from "@mui/material/IconButton";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  PRODUCTION,
  SERVER_API_ADDRESS,
  LOCAL_API_ADDRESS,
} from "../actions/auth";
function Copyright() {
  return (
    <Typography variant="body2" color="white" align="center">
      {"Copyright © "}
      Gogoz {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Login() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [showSnack, setShowSnack] = useState(false);
  const [snack, setSnack] = useState("");
  const [clearText, setClearText] = useState("");

  useEffect(() => {
    setLoaded(true);
  }, []);

  function onSubmit(e) {
    e.preventDefault();
    dispatch(login(phoneNumber, password));
  }

  if (state.auth.alert) {
    if (state.auth.alert.title == "Welcome!") {
    } else {
      setSnack({
        title: state.auth.alert.title,
        message: state.auth.alert.message,
        type: state.auth.alert.type,
      });
      setShowSnack(true);
      dispatch({ type: "REMOVE_ALERT" });
    }
  }

  if (state.auth.isAuthenticated) {
    if (state.auth.user.is_manager) {
      return <Navigate to="/" />;
    } else {
      console.log("AUTHERROR");
      dispatch({
        type: "AUTH_ERROR",
      });
    }
  } else {
    return (
      <Fragment>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          autoHideDuration={6000}
          open={showSnack}
          onClose={() => setShowSnack(false)}
        >
          <Card style={{ minWidth: "350px", padding: "10px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex" }}>
                {snack["type"] == "SUCCESS" ? (
                  <CheckCircleIcon
                    style={{ color: "#2ca25f", fontSize: "44px" }}
                  />
                ) : snack["type"] == "MESSAGE" ? (
                  <ErrorIcon style={{ color: "#FFA500", fontSize: "44px" }} />
                ) : (
                  <ErrorIcon style={{ color: "#ff3333", fontSize: "44px" }} />
                )}
                <div style={{ marginLeft: "10px" }}>
                  <b style={{ fontSize: "15px" }}>{snack["title"]}</b>
                  <br></br>
                  <span>{snack["message"]}</span>
                </div>
              </div>
              <IconButton size="small">
                <CloseIcon
                  onClick={() => setShowSnack(false)}
                  style={{ color: "#FFA500" }}
                />
              </IconButton>
            </div>
          </Card>
        </Snackbar>
        <AppBar
          position="fixed"
          style={{
            backgroundColor: "white",
          }}
        >
          <Toolbar
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <img
              style={{ maxHeight: "50px", margin: "10px" }}
              src={require("../img/appbar.png")}
            />
            <a
              style={{ textDecoration: "none" }}
              href={
                PRODUCTION ? SERVER_API_ADDRESS + "" : LOCAL_API_ADDRESS + ""
              }
            >
              <Button
                style={{
                  backgroundColor: "white",
                  color: "black",
                  fontSize: "16px",
                  marginTop: "3px",
                }}
              >
                <ChevronLeftIcon style={{ fontSize: "35px" }} /> Back to Home
              </Button>
            </a>
          </Toolbar>
        </AppBar>
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transition: "opacity 1s",
            opacity: loaded ? 1 : 0,
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundImage:
                "linear-gradient(to right, rgba(100,64,0,0.6),  rgba(100, 64, 0, 0.6)), url(https://images.unsplash.com/photo-1504674900247-0877df9cc836?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80)",
              height: "100%",
              paddingTop: "50px",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                boxShadow: "0 8px 18px 0 rgba(0,0,0,0.2)",
                padding: "20px",
                borderRadius: "6px",
                backgroundColor: "white",
                paddingRight: "20px",
                maxWidth: "350px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  color: "black",
                }}
              >
                <div style={{ marginTop: "-7px" }}>
                  <span style={{ fontSize: "32px" }}>Gogoz</span>
                  <br></br>
                  <span style={{ fontSize: "20px" }}>Manager Login</span>
                </div>
                <img
                  style={{ maxHeight: "60px" }}
                  src={require("../img/onlylogo.png")}
                />
              </div>
              <div
                className={state.auth.alert ? "shake" : ""}
                style={{
                  height: "20px",
                  marginTop: "15px",
                  textAlign: "center",
                  color: "red",
                }}
              >
                {state.auth.alert
                  ? state.auth.alert.type == "ERROR"
                    ? state.auth.alert.message
                    : ""
                  : ""}
              </div>
              <div
                style={{
                  margin: "5px 0px 0px 0px",
                }}
              >
                <form onSubmit={onSubmit}>
                  <TextField
                    style={{ backgroundColor: "white", borderRadius: "6px" }}
                    variant="outlined"
                    required
                    fullWidth
                    margin="normal"
                    name="phonenumber"
                    autoComplete="phonenumber"
                    autoFocus
                    placeholder="Phone number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                  <TextField
                    style={{ backgroundColor: "white", borderRadius: "6px" }}
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    margin="normal"
                    placeholder="Password"
                    type={clearText ? "text" : "password"}
                    autoComplete="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          size="small"
                          style={{ marginRight: "-8px", paddingLeft: "10px" }}
                          onClick={() => setClearText(!clearText)}
                        >
                          {clearText ? (
                            <VisibilityOffIcon style={{ color: "#FFA500" }} />
                          ) : (
                            <VisibilityIcon style={{ color: "#FFA500" }} />
                          )}
                        </IconButton>
                      ),
                    }}
                  />
                  <div style={{ marginTop: "10px", textAlign: "center" }}>
                    <Link style={{ color: "black" }} to="/forgotpassword">
                      Forgot Password?
                    </Link>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      style={{ height: "30px", backgroundColor: "#FFA500" }}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      <span
                        style={{
                          fontSize: "18px",
                          width: "100px",
                        }}
                      >
                        Login
                      </span>
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <Box style={{ position: "absolute", right: 20, bottom: 20 }}>
            <Copyright />
          </Box>
        </div>
      </Fragment>
    );
  }
}
