import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import moment from "moment";

import makeStyles from "@mui/styles/makeStyles";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { alertMessage } from "../actions/auth";
import { Bar } from "react-chartjs-2";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Chart } from "chart.js/auto";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Fab } from "@mui/material";
import Fade from "@mui/material/Fade";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Line } from "react-chartjs-2";
import { refreshData } from "../actions/auth";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import {
  PRODUCTION,
  SERVER_API_ADDRESS,
  LOCAL_API_ADDRESS,
} from "../actions/auth";

function Dashboard() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedNoti, setSelectedNoti] = useState("EVERYONE");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [pendingOrders, setPendingOrders] = useState([]);
  const [deliveredOrders, setDeliveredOrders] = useState([]);
  const [cancelledOrders, setCancelledOrders] = useState([]);
  const [vendorIdList, setVendorIdList] = useState([]);
  const [deliveryPartnerIdList, setDeliveryPartnerIdList] = useState([]);

  function getData() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };
    body = JSON.stringify(body);
    axios
      .get(
        PRODUCTION
          ? SERVER_API_ADDRESS + "api/get_vendors_list"
          : LOCAL_API_ADDRESS + "api/get_vendors_list"
      )
      .then((res) => {
        setVendorIdList(res.data["data"]);
      });
    axios
      .get(
        PRODUCTION
          ? SERVER_API_ADDRESS + "api/get_delivery_partners_list"
          : LOCAL_API_ADDRESS + "api/get_delivery_partners_list"
      )
      .then((res) => {
        console.log(res.data);
        setDeliveryPartnerIdList(res.data["data"]);
        setIsLoading(false);
      });

    axios
      .post(
        PRODUCTION
          ? SERVER_API_ADDRESS + "api/all_orders"
          : LOCAL_API_ADDRESS + "api/all_orders"
      )
      .then((res) => {
        setPendingOrders(res.data["data"]["pending_orders"].length);
        setDeliveredOrders(res.data["data"]["delivered_orders"].length);
        setCancelledOrders(res.data["data"]["cancelled_orders"].length);

        setIsLoading(false);
      });
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      getData();
    }
  }, []);

  if (state.auth.refresh.includes("dashboard")) {
    getData();
    dispatch(refreshData("dashboard"));
  }

  const graph = {
    dataLine: {
      labels: ["WEEK 1", "WEEK 2", "WEEK 3", "WEEK 4"],
      datasets: [
        {
          label: "Holistic ranking",
          fill: true,
          lineTension: 0.3,
          backgroundColor: "rgba(225, 204,230, .3)",
          borderColor: "purple",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "red",
          pointBackgroundColor: "red",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "red",
          pointHoverBorderColor: "red",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: [10, 60, 43, 67],
        },
      ],
    },
  };

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.user.is_manager) {
        if (isLoading) {
          return (
            <Fragment>
              <Sidebar />
              <div
                style={{
                  maxWidth: "88vw",
                  marginTop: "45vh",
                  marginLeft: "8vw",
                  backgroundColor: "white",
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            </Fragment>
          );
        } else {
          return (
            <Fragment>
              <Sidebar />
              <div
                style={{
                  margin: "80px 0px 20px 95px",
                  backgroundColor: "white",
                }}
              >
                <Fade in={true}>
                  <Grid container spacing={5}>
                    <Grid item md={8}>
                      <Grid container spacing={3}>
                        <Grid item md={4}>
                          <Link
                            to={{
                              pathname: "/orders",
                              search: "?tab=PENDING",
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            <CardActionArea
                              sx={[
                                {
                                  height: "35vh",
                                  borderRadius: "26px",
                                  backgroundColor: "brown",
                                  color: "white",
                                  border: "1.5px solid #d7ecff",
                                  transition:
                                    "background-color 0.5s, border-color 0.5s",
                                },
                                (theme) => ({
                                  "&:hover": {
                                    backgroundColor: "#ebf5ff",
                                    borderColor: "#FFA500",
                                    color: "brown",
                                  },
                                  ".MuiCardActionArea-focusHighlight": {
                                    background: "transparent",
                                  },

                                  "&& .MuiTouchRipple-child": {
                                    backgroundColor: "#084594",
                                  },
                                }),
                              ]}
                              style={{
                                padding: "17px",
                                marginBottom: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  height: "100%",
                                  justifyContent: "space-between",
                                  alignItems: "flex-start",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    height: "100%",
                                    flexDirection: "column",
                                  }}
                                >
                                  <span style={{ fontSize: "20px" }}>
                                    PENDING ORDERS
                                  </span>
                                  <div
                                    style={{
                                      marginTop: "-20px",
                                      fontSize: "60px",
                                    }}
                                  >
                                    {pendingOrders}
                                  </div>
                                </div>
                                <BorderColorIcon style={{ fontSize: "50px" }} />
                              </div>
                            </CardActionArea>
                          </Link>
                        </Grid>
                        <Grid item md={4}>
                          <Link
                            to={{
                              pathname: "/orders",
                              search: "?tab=DELIVERED",
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            <CardActionArea
                              sx={[
                                {
                                  height: "35vh",
                                  borderRadius: "26px",
                                  backgroundColor: "#7BCCB5",
                                  color: "white",
                                  border: "1.5px solid #d7ecff",
                                  transition:
                                    "background-color 0.5s, border-color 0.5s",
                                },
                                (theme) => ({
                                  "&:hover": {
                                    backgroundColor: "#ebf5ff",
                                    borderColor: "#FFA500",
                                    color: "brown",
                                  },
                                  ".MuiCardActionArea-focusHighlight": {
                                    background: "transparent",
                                  },

                                  "&& .MuiTouchRipple-child": {
                                    backgroundColor: "#084594",
                                  },
                                }),
                              ]}
                              style={{
                                padding: "17px",
                                marginBottom: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  height: "100%",
                                  justifyContent: "space-between",
                                  alignItems: "flex-start",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    height: "100%",
                                    flexDirection: "column",
                                  }}
                                >
                                  <span style={{ fontSize: "20px" }}>
                                    DELIVERED ORDERS
                                  </span>
                                  <div
                                    style={{
                                      marginTop: "-20px",
                                      fontSize: "60px",
                                    }}
                                  >
                                    {deliveredOrders}
                                  </div>
                                </div>
                                <ShoppingBasketIcon
                                  style={{ fontSize: "50px" }}
                                />
                              </div>
                            </CardActionArea>
                          </Link>
                        </Grid>
                        <Grid item md={4}>
                          <Link
                            to={{
                              pathname: "/orders",
                              search: "?tab=CANCELLED",
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            <CardActionArea
                              sx={[
                                {
                                  height: "35vh",
                                  borderRadius: "26px",
                                  backgroundColor: "#FED8B1",
                                  color: "black",
                                  border: "1.5px solid #d7ecff",
                                  transition:
                                    "background-color 0.5s, border-color 0.5s",
                                },
                                (theme) => ({
                                  "&:hover": {
                                    backgroundColor: "#ebf5ff",
                                    borderColor: "#FFA500",
                                    color: "brown",
                                  },
                                  ".MuiCardActionArea-focusHighlight": {
                                    background: "transparent",
                                  },

                                  "&& .MuiTouchRipple-child": {
                                    backgroundColor: "#084594",
                                  },
                                }),
                              ]}
                              style={{
                                padding: "17px",
                                marginBottom: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  height: "100%",
                                  justifyContent: "space-between",
                                  alignItems: "flex-start",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    height: "100%",
                                    flexDirection: "column",
                                  }}
                                >
                                  <span style={{ fontSize: "20px" }}>
                                    CANCELLED ORDERS
                                  </span>
                                  <div
                                    style={{
                                      marginTop: "-20px",
                                      fontSize: "60px",
                                    }}
                                  >
                                    {cancelledOrders}
                                  </div>
                                </div>
                                <DoDisturbIcon style={{ fontSize: "50px" }} />
                              </div>
                            </CardActionArea>
                          </Link>
                        </Grid>
                      </Grid>
                      <Box style={{ marginTop: "15px", width: "100%" }}>
                        <Line
                          height={90}
                          width={200}
                          data={graph.dataLine}
                          options={{ responsive: true }}
                        />
                      </Box>
                      {/* <Grid container spacing={3}>
                        <Grid item md={6}>
                          
                          <h4>Send Notification</h4>
                          <Grid container spacing={1}>
                            <Grid item md={4}>
                              {selectedNoti == "EVERYONE" ? (
                                <Button
                                  fullWidth
                                  value="EVERYONE"
                                  onClick={handlePageSelect}
                                  p={2}
                                  style={{
                                    marginTop: "-15px",
                                    backgroundColor: "#FFA500",
                                    color: "white",
                                    height: "25px",
                                  }}
                                  borderRadius={6}
                                >
                                  <h3
                                    style={{
                                      marginTop: "17px",
                                      fontSize: "15px",
                                      letterSpacing: "1.5px",
                                    }}
                                  >
                                    EVERYONE
                                  </h3>
                                </Button>
                              ) : (
                                <Button
                                  fullWidth
                                  value="EVERYONE"
                                  onClick={handlePageSelect}
                                  p={2}
                                  style={{
                                    height: "25px",
                                    marginTop: "-15px",
                                    backgroundColor: "#d7ecff",
                                    color: "white",
                                  }}
                                  borderRadius={6}
                                >
                                  <h3
                                    style={{
                                      marginTop: "17px",
                                      fontSize: "15px",
                                      color: "#084594",
                                    }}
                                  >
                                    EVERYONE
                                  </h3>
                                </Button>
                              )}
                            </Grid>
                            <Grid item md={4}>
                              {selectedNoti == "CUSTOMERS" ? (
                                <Button
                                  fullWidth
                                  value="CUSTOMERS"
                                  onClick={handlePageSelect}
                                  p={2}
                                  style={{
                                    marginTop: "-15px",
                                    backgroundColor: "#FFA500",
                                    color: "white",
                                    height: "25px",
                                  }}
                                  borderRadius={6}
                                >
                                  <h3
                                    style={{
                                      marginTop: "17px",
                                      fontSize: "15px",
                                      letterSpacing: "1.5px",
                                    }}
                                  >
                                    CUSTOMERS
                                  </h3>
                                </Button>
                              ) : (
                                <Button
                                  fullWidth
                                  value="CUSTOMERS"
                                  onClick={handlePageSelect}
                                  p={2}
                                  style={{
                                    height: "25px",
                                    marginTop: "-15px",
                                    backgroundColor: "#d7ecff",
                                    color: "white",
                                  }}
                                  borderRadius={6}
                                >
                                  <h3
                                    style={{
                                      marginTop: "17px",
                                      fontSize: "15px",
                                      color: "#084594",
                                    }}
                                  >
                                    CUSTOMERS
                                  </h3>
                                </Button>
                              )}
                            </Grid>
                            <Grid item md={4}>
                              {selectedNoti == "VENDORS" ? (
                                <Button
                                  fullWidth
                                  value="VENDORS"
                                  onClick={handlePageSelect}
                                  p={2}
                                  style={{
                                    marginTop: "-15px",
                                    backgroundColor: "#FFA500",
                                    color: "white",
                                    height: "25px",
                                  }}
                                  borderRadius={6}
                                >
                                  <h3
                                    style={{
                                      marginTop: "17px",
                                      fontSize: "15px",
                                      letterSpacing: "1.5px",
                                    }}
                                  >
                                    VENDORS
                                  </h3>
                                </Button>
                              ) : (
                                <Button
                                  fullWidth
                                  value="VENDORS"
                                  onClick={handlePageSelect}
                                  p={2}
                                  style={{
                                    height: "25px",
                                    marginTop: "-15px",
                                    backgroundColor: "#d7ecff",
                                    color: "white",
                                  }}
                                  borderRadius={6}
                                >
                                  <h3
                                    style={{
                                      marginTop: "17px",
                                      fontSize: "15px",
                                      color: "#084594",
                                    }}
                                  >
                                    VENDORS
                                  </h3>
                                </Button>
                              )}
                            </Grid>
                            <Grid item md={4}>
                              {selectedNoti == "DELIVERY" ? (
                                <Button
                                  fullWidth
                                  value="DELIVERY"
                                  onClick={handlePageSelect}
                                  p={2}
                                  style={{
                                    marginTop: "-15px",
                                    backgroundColor: "#FFA500",
                                    color: "white",
                                    height: "25px",
                                  }}
                                  borderRadius={6}
                                >
                                  <h3
                                    style={{
                                      marginTop: "17px",
                                      fontSize: "15px",
                                      letterSpacing: "1.5px",
                                    }}
                                  >
                                    DELIVERY
                                  </h3>
                                </Button>
                              ) : (
                                <Button
                                  fullWidth
                                  value="DELIVERY"
                                  onClick={handlePageSelect}
                                  p={2}
                                  style={{
                                    height: "25px",
                                    marginTop: "-15px",
                                    backgroundColor: "#d7ecff",
                                    color: "white",
                                  }}
                                  borderRadius={6}
                                >
                                  <h3
                                    style={{
                                      marginTop: "17px",
                                      fontSize: "15px",
                                      color: "#084594",
                                    }}
                                  >
                                    DELIVERY
                                  </h3>
                                </Button>
                              )}
                            </Grid>
                            <Grid item md={4}>
                              {selectedNoti == "AGENTS" ? (
                                <Button
                                  fullWidth
                                  value="AGENTS"
                                  onClick={handlePageSelect}
                                  p={2}
                                  style={{
                                    marginTop: "-15px",
                                    backgroundColor: "#FFA500",
                                    color: "white",
                                    height: "25px",
                                  }}
                                  borderRadius={6}
                                >
                                  <h3
                                    style={{
                                      marginTop: "17px",
                                      fontSize: "15px",
                                      letterSpacing: "1.5px",
                                    }}
                                  >
                                    AGENTS
                                  </h3>
                                </Button>
                              ) : (
                                <Button
                                  fullWidth
                                  value="AGENTS"
                                  onClick={handlePageSelect}
                                  p={2}
                                  style={{
                                    height: "25px",
                                    marginTop: "-15px",
                                    backgroundColor: "#d7ecff",
                                    color: "white",
                                  }}
                                  borderRadius={6}
                                >
                                  <h3
                                    style={{
                                      marginTop: "17px",
                                      fontSize: "15px",
                                      color: "#084594",
                                    }}
                                  >
                                    AGENTS
                                  </h3>
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                          <br></br>
                          <TextField
                            fullWidth
                            id="outlined-basic"
                            placeholder="Subject"
                            variant="outlined"
                            value={subject}
                            size="small"
                            onChange={(e) => setSubject(e.target.value)}
                          />
                          <br></br> <br></br>
                          <TextField
                            fullWidth
                            id="outlined-basic"
                            placeholder="Message"
                            variant="outlined"
                            size="small"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                          />
                          <br></br> <br></br>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              style={{
                                fontSize: "15px",
                                marginTop: "-5px",
                                height: "35px",
                                backgroundColor: "#FFA500",
                              }}
                              onClick={() => sendNotification()}
                              variant="contained"
                              color="primary"
                            >
                              <span
                                style={{
                                  marginTop: "3px",
                                }}
                              >
                                Send
                              </span>
                              <ChevronRightIcon
                                style={{
                                  fontSize: "30px",
                                  color: "white",
                                  marginRight: "-10px",
                                }}
                              />
                            </Button>
                          </div>
                        </Grid>
                      </Grid> */}
                    </Grid>
                    <Grid item md={3} style={{ marginTop: "-25px" }}>
                      <h4>Vendor Payments</h4>
                      <div style={{ marginTop: "-15px" }}>
                        {vendorIdList.slice(0, 3).map((vendor, index) => (
                          <Link
                            to={{
                              pathname: "/payments",
                              search:
                                "?tab=VENDOR&&vendorId=" + vendor["vendor_id"],
                            }}
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            <CardActionArea
                              style={{
                                borderRadius: "5px",
                                marginBottom: "15px",
                                border: "1.5px solid #FFA500",
                                padding: "15px",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <SupervisedUserCircleIcon
                                  style={{
                                    fontSize: "50px",
                                    marginRight: "15px",
                                  }}
                                />

                                <p
                                  style={{
                                    fontFamily: "Lato-Bold",
                                    fontSize: "16px",
                                  }}
                                >
                                  {vendor["vendor_name"]}
                                </p>
                              </div>
                              <p>{vendor["pending_payments"]}</p>
                            </CardActionArea>
                          </Link>
                        ))}
                      </div>
                      <h4>Delivery Payments</h4>
                      <div style={{ marginTop: "-15px" }}>
                        {deliveryPartnerIdList
                          .slice(0, 3)
                          .map((partner, index) => (
                            <Link
                              to={{
                                pathname: "/payments",
                                search:
                                  "?tab=DELIVERY&&deliveryPartnerId=" +
                                  partner["delivery_partner_id"],
                              }}
                              path
                              style={{ textDecoration: "none", color: "black" }}
                            >
                              <CardActionArea
                                style={{
                                  borderRadius: "5px",
                                  marginBottom: "15px",
                                  border: "1.5px solid #FFA500",
                                  padding: "15px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <SupervisedUserCircleIcon
                                    style={{
                                      fontSize: "50px",
                                      marginRight: "15px",
                                    }}
                                  />

                                  <p
                                    style={{
                                      fontFamily: "Lato-Bold",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {partner["partner_name"]}
                                  </p>
                                </div>
                                <p>{partner["pending_payments"]}</p>
                              </CardActionArea>
                            </Link>
                          ))}
                      </div>
                    </Grid>
                    <br></br>
                  </Grid>
                </Fade>
              </div>
            </Fragment>
          );
        }
      } else {
        createAlert("ERROR", "Permission denied.", "Please Login as manager");
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Dashboard;
