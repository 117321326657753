import React, { useEffect, useState, useRef, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { alertMessage } from "../actions/auth";
import Paper from "@mui/material/Paper";
import Fab from "@mui/material/Fab";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import PublishIcon from "@mui/icons-material/Publish";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import {
  PRODUCTION,
  SERVER_API_ADDRESS,
  LOCAL_API_ADDRESS,
} from "../actions/auth";

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function Settings() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [open, setOpen] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  function onFileUpload() {
    const formData = new FormData();
    formData.append("file", selectedFile, selectedFile.name);
    formData.append("user_id", state.auth.user.user_id);
    console.log(selectedFile);

    // Request made to the backend api
    // Send formData object

    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        // "Content-Type": "application/json",
      },
    };
    var time = new Date();

    axios
      .post(
        PRODUCTION
          ? SERVER_API_ADDRESS + "api/upload_user_image"
          : LOCAL_API_ADDRESS + "api/upload_user_image"
      )
      .then(() => {
        window.location.reload();
      });

    document.getElementById("contained-button-file").value = "";
  }

  function onFileChange(event) {
    setSelectedFile(event.target.files[0]);
  }

  function onFileClick(event) {
    document.getElementById("contained-button-file").value = "";
  }
  const didMount = useRef(false);

  const [selectedFile, setSelectedFile] = useState("");
  useEffect(() => {
    if (!didMount.current) {
      return (didMount.current = true);
    }
    onFileUpload();
  }, [selectedFile]);

  // function changePhoneNumber() {
  //   if (
  //     phoneNumber.length == 0 ||
  //     (phoneNumber.length > 15 && /\D/.test(phoneNumber))
  //   ) {
  //     createAlert("ERROR", "Error", "Enter valid phone number.");
  //   } else {
  //     var body = {};
  //     const config = {
  //       headers: {
  //         Authorization: state.auth.token,
  //         "X-CSRFToken": state.auth.csrfToken,
  //         "Content-Type": "application/json",
  //       },
  //     };

  //     body["email"] = state.auth.user.email;
  //     body["phone_number"] = phoneNumber;
  //     body = JSON.stringify(body);
  //     axios
  //       .post("https://api.gogoz.in/change_phone_number", body, config)
  //       .then((res) => {
  //         createAlert("SUCCESS", "Success", "Phone Number updated.");
  //       });
  //   }
  // }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.user.is_manager) {
        return (
          <Fragment>
            <Sidebar />

            <div
              style={{
                margin: "58px 40px 20px 90px",
                backgroundColor: "white",
                display: "flex",
              }}
            >
              <Grid container spacing={10}>
                <Grid item md={6}>
                  <h2>PERSONAL DETAILS</h2>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>
                      <h3>{state.auth.user.full_name}</h3>
                      <span>
                        Name :<b>{state.auth.user.phone_number}</b>
                      </span>
                    </span>
                    <input
                      id="contained-button-file"
                      style={{
                        display: "none",
                        height: "150px",
                        cursor: "pointer",
                        width: "150px",
                      }}
                      type="file"
                      onChange={(e) => onFileChange(e)}
                      onClick={(e) => onFileClick(e)}
                    />
                    <label htmlFor="contained-button-file">
                      <Avatar
                        style={{
                          height: "150px",
                          width: "150px",
                          marginTop: "-50px",
                          cursor: "pointer",
                        }}
                        alt={state.auth.user.full_name}
                        src={
                          PRODUCTION
                            ? SERVER_API_ADDRESS +
                              "media" +
                              state.auth.user.user_id +
                              ".jpg"
                            : LOCAL_API_ADDRESS +
                              "media" +
                              state.auth.user.user_id +
                              ".jpg"
                        }
                      />
                      <h5 style={{ textAlign: "center", marginTop: "5px" }}>
                        UPLOAD PHOTO
                      </h5>
                    </label>
                  </div>
                  <br></br>
                  <br></br>
                  <span>
                    Email : <b>{state.auth.user.email}</b>
                  </span>{" "}
                  <br></br>
                  <span>
                    Phone Number : <b>{state.auth.user.phone_number}</b>
                  </span>
                  <br></br>
                  {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <TextField
                      style={{ marginTop: "10px" }}
                      fullWidth
                      size="small"
                      placeholder="Phone Number"
                      variant="outlined"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                    <Button
                      style={{
                        fontSize: "15px",
                        height: "40px",
                        marginTop: "10px",
                        marginLeft: "10px",
                      }}
                      onClick={() => {
                        // changePhoneNumber();
                      }}
                      variant="contained"
                      color="primary"
                    >
                      <h5>Change</h5>
                    </Button>
                  </div> */}
                </Grid>
                <Grid item md={6}>
                  {/* <div>
                    <h2>PARENTS DETAILS</h2>
                  </div>
                  <br></br>
                  <br></br> */}
                </Grid>
              </Grid>
            </div>
          </Fragment>
        );
      } else {
        createAlert("ERROR", "Permission Denied.", "Please Login as Manager");
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Settings;
