import React, { Fragment, useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { logout } from "../actions/auth";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { alertMessage } from "../actions/auth";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

export default function Logout() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);

  function getout() {
    dispatch(logout());
    return null;
  }

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <Fragment>
      <div
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: "opacity 1s",
          opacity: loaded ? 1 : 0,
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundImage:
              "linear-gradient(to right, rgba(33,41,100,0.9),  rgba(33, 41, 100, 0.9)), url(https://img.freepik.com/free-vector/education-pattern-background-doodle-style_53876-115365.jpg?w=740)",
            height: "100%",
            paddingTop: "50px",
          }}
        >
          <AppBar
            position="fixed"
            style={{
              backgroundColor: "white",
            }}
          >
            <Toolbar
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <img
                style={{ maxHeight: "50px", margin: "10px" }}
                src={require("../img/appbar.png")}
              />
              <a
                style={{ textDecoration: "none" }}
                href="https://manager.gogoz.in/"
              >
                <Button
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    fontSize: "16px",
                    marginTop: "3px",
                  }}
                >
                  <ChevronLeftIcon style={{ fontSize: "35px" }} /> Back to Home
                </Button>
              </a>
            </Toolbar>
          </AppBar>
          <div
            style={{
              padding: "50px",
              backgroundColor: "white",
              textAlign: "center",
              borderRadius: "6px",
            }}
          >
            <div style={{ marginTop: "-30px" }}>
              <h2>Do you want to Log out?</h2>
              <br></br>
              <Button
                style={{ height: "40px", backgroundColor: "#FFA500" }}
                type="button"
                onClick={() => getout()}
                variant="contained"
                color="primary"
              >
                <span
                  style={{
                    fontSize: "18px",
                    width: "150px",
                    height: "30px",
                  }}
                >
                  Logout
                </span>{" "}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
