import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import moment from "moment";

import makeStyles from "@mui/styles/makeStyles";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { alertMessage } from "../actions/auth";
import { Bar } from "react-chartjs-2";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Chart } from "chart.js/auto";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Fab } from "@mui/material";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Checkbox from "@mui/material/Checkbox";
import {
  PRODUCTION,
  SERVER_API_ADDRESS,
  LOCAL_API_ADDRESS,
} from "../actions/auth";
function Register() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedNoti, setSelectedNoti] = useState("EVERYONE");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [clearText, setClearText] = useState("");
  const [sendOTP, setSendOTP] = useState(false);
  const [isServiceVendor, setIsServiceVendor] = useState(false);
  const [vendorId, setVendorId] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [selectedRegi, setSelectedRegi] = useState("DELIVERY");

  const handlePageSelect = (event) => {
    setSelectedNoti(event.currentTarget.value);
  };

  const handleRegiSelect = (event) => {
    setSelectedRegi(event.currentTarget.value);
  };

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function sendOTPonborder() {
    var body = {};
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    body["phone_number"] = phoneNumber;
    body = JSON.stringify(body);

    axios
      .post(
        PRODUCTION
          ? SERVER_API_ADDRESS + "api/send_otp_onboard"
          : LOCAL_API_ADDRESS + "api/send_otp_onboard",
        body,
        config
      )
      .then((res) => {
        setPhoneNumber("");
        createAlert("SUCCESS", "Success", "OTP Sent successfully.");
      });
  }

  function sendNotification() {
    var body = {};
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    body["title"] = title;
    body["message"] = message;
    body["send_to"] = selectedNoti;

    body = JSON.stringify(body);

    axios
      .post(
        PRODUCTION
          ? SERVER_API_ADDRESS + "api/send_notification_manager"
          : LOCAL_API_ADDRESS + "api/send_notification_manager",
        body,
        config
      )
      .then((res) => {
        setTitle("");
        setMessage("");
        createAlert("SUCCESS", "Success", "Notification Sent successfully.");
      });
  }

  function registerNewUser() {
    var body = {};
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    var validEmailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (email.match(validEmailRegex)) {
      if (phoneNumber.length == 10) {
        if (name.length !== 0) {
          body["email"] = email;
          body["phone_number"] = phoneNumber;
          body["full_name"] = name;
          body["is_service_agent"] = isServiceVendor.toString();
          body["vendor_id"] = vendorId;

          body = JSON.stringify(body);

          // if (selectedRegi == "MANAGER") {
          //   axios
          //     .post(
          //       PRODUCTION
          //         ? SERVER_API_ADDRESS + "api/auth/register_manager"
          //         : LOCAL_API_ADDRESS + "api/auth/register_manager",
          //       body,
          //       config
          //     )
          //     .then((res) => {
          //       createAlert(
          //         "SUCCESS",
          //         "Registration complete.",
          //         "MANAGER registered successfully."
          //       );
          //     });
          // } else
          if (selectedRegi == "ONBOARDER") {
            axios
              .post(
                PRODUCTION
                  ? SERVER_API_ADDRESS + "api/auth/register_onboarding"
                  : LOCAL_API_ADDRESS + "api/auth/register_onboarding",
                body,
                config
              )
              .then((res) => {
                createAlert(
                  "SUCCESS",
                  "Registration complete.",
                  "ONBOARDER registered successfully."
                );
              })
              .catch((err) => {
                setSendOTP(true);
                createAlert(
                  "ERROR",
                  "Phone number already exists.",
                  "Click SEND OTP button to proceed."
                );
              });
          } else if (selectedRegi == "DELIVERY") {
            if (isServiceVendor) {
              if (vendorId.length != 0) {
                axios
                  .post(
                    PRODUCTION
                      ? SERVER_API_ADDRESS +
                          "api/auth/register_delivery_partner"
                      : LOCAL_API_ADDRESS +
                          "api/auth/register_delivery_partner",
                    body,
                    config
                  )
                  .then((res) => {
                    createAlert(
                      "SUCCESS",
                      "Registration complete.",
                      "DELIVERY/SERVICE AGENT registered successfully."
                    );
                  })
                  .catch((err) => {
                    createAlert(
                      "ERROR",
                      "Phone number/Email already exists.",
                      "Try different phone number/email to register."
                    );
                    console.log(err);
                  });
              } else {
                createAlert(
                  "ERROR",
                  "Vendor Id required",
                  "Please enter Vendor ID"
                );
              }
            } else {
              axios
                .post(
                  PRODUCTION
                    ? SERVER_API_ADDRESS + "api/auth/register_delivery_partner"
                    : LOCAL_API_ADDRESS + "api/auth/register_delivery_partner",
                  body,
                  config
                )
                .then((res) => {
                  createAlert(
                    "SUCCESS",
                    "Registration complete.",
                    "DELIVERY/SERVICE AGENT registered successfully."
                  );
                })
                .catch((err) => {
                  createAlert(
                    "ERROR",
                    "Phone number/Email already exists.",
                    "Try different phone number/email to register."
                  );
                  console.log(err);
                });
            }
          } else {
            createAlert(
              "ERROR",
              "Full name required",
              "Please enter full name"
            );
          }
        }
      } else {
        createAlert(
          "ERROR",
          "Phone number required 10 digits",
          "Please enter 10 digit number."
        );
      }
    } else {
      createAlert("ERROR", "Email invalid", "Please enter valid email address");
    }
    setPhoneNumber("");
    setEmail("");
    setName("");
    setIsServiceVendor(false);

    setIsLoading(false);
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
    }
  }, []);

  if (state.auth.refresh == "REFRESH_DATA") {
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.user.is_manager) {
        if (isLoading) {
          return (
            <Fragment>
              <Sidebar />
              <div
                style={{
                  maxWidth: "88vw",
                  marginTop: "45vh",
                  marginLeft: "8vw",
                  backgroundColor: "white",
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            </Fragment>
          );
        } else {
          return (
            <Fragment>
              <Sidebar />
              <div
                style={{
                  margin: "80px 0px 20px 95px",
                  backgroundColor: "white",
                }}
              >
                <Grid container spacing={3} style={{ marginTop: "-50px" }}>
                  <Grid item md={6}>
                    <h4>Register {selectedRegi}</h4>
                    <Grid container spacing={1}>
                      {/* <Grid item md={6}>
                        {selectedRegi == "MANAGER" ? (
                          <Button
                            fullWidth
                            value="MANAGER"
                            onClick={handleRegiSelect}
                            p={2}
                            style={{
                              marginTop: "-15px",
                              backgroundColor: "#FFA500",
                              color: "white",
                              height: "25px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                letterSpacing: "1.5px",
                              }}
                            >
                              MANAGER
                            </h3>
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            value="MANAGER"
                            onClick={handleRegiSelect}
                            p={2}
                            style={{
                              height: "25px",
                              marginTop: "-15px",
                              backgroundColor: "#d7ecff",
                              color: "white",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                color: "#084594",
                              }}
                            >
                              MANAGER
                            </h3>
                          </Button>
                        )}
                      </Grid> */}
                      <Grid item md={6}>
                        {selectedRegi == "ONBOARDER" ? (
                          <Button
                            fullWidth
                            value="ONBOARDER"
                            onClick={handleRegiSelect}
                            p={2}
                            style={{
                              marginTop: "-15px",
                              backgroundColor: "#FFA500",
                              color: "white",
                              height: "25px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                letterSpacing: "1.5px",
                              }}
                            >
                              ONBOARDER
                            </h3>
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            value="ONBOARDER"
                            onClick={handleRegiSelect}
                            p={2}
                            style={{
                              height: "25px",
                              marginTop: "-15px",
                              backgroundColor: "#d7ecff",
                              color: "white",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                color: "#084594",
                              }}
                            >
                              ONBOARDER
                            </h3>
                          </Button>
                        )}
                      </Grid>
                      <Grid item md={6}>
                        {selectedRegi == "DELIVERY" ? (
                          <Button
                            fullWidth
                            value="DELIVERY"
                            onClick={handleRegiSelect}
                            p={2}
                            style={{
                              marginTop: "-15px",
                              backgroundColor: "#FFA500",
                              color: "white",
                              height: "25px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                letterSpacing: "1.5px",
                              }}
                            >
                              DELIVERY
                            </h3>
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            value="DELIVERY"
                            onClick={handleRegiSelect}
                            p={2}
                            style={{
                              height: "25px",
                              marginTop: "-15px",
                              backgroundColor: "#d7ecff",
                              color: "white",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                color: "#084594",
                              }}
                            >
                              DELIVERY
                            </h3>
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                    {selectedRegi == "DELIVERY" ? (
                      <div style={{ display: "flex" }}>
                        <h4 style={{ marginTop: "0px" }}>Service agent</h4>
                        <Checkbox
                          disableRipple={true}
                          labelStyle={{ color: "#FFA500" }}
                          iconStyle={{ fill: "white" }}
                          checked={isServiceVendor}
                          onChange={(e) => {
                            setIsServiceVendor(e.target.checked);
                          }}
                          value={1}
                          style={{
                            transform: "scale(1.2)",
                            color: "#FFA500",
                            marginTop: "-24px",
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {isServiceVendor ? (
                      <div style={{ display: "flex" }}>
                        <TextField
                          style={{
                            backgroundColor: "white",
                            borderRadius: "6px",
                          }}
                          variant="outlined"
                          required
                          fullWidth
                          margin="normal"
                          name="vendorId"
                          autoComplete="vendor_id"
                          autoFocus
                          placeholder="Vendor id"
                          value={vendorId}
                          onChange={(e) => setVendorId(e.target.value)}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <TextField
                      style={{ backgroundColor: "white", borderRadius: "6px" }}
                      variant="outlined"
                      required
                      fullWidth
                      margin="normal"
                      name="phonenumber"
                      autoComplete="phonenumber"
                      autoFocus
                      placeholder="Phone number"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                    <TextField
                      style={{ backgroundColor: "white", borderRadius: "6px" }}
                      variant="outlined"
                      required
                      fullWidth
                      margin="normal"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />{" "}
                    <TextField
                      style={{ backgroundColor: "white", borderRadius: "6px" }}
                      variant="outlined"
                      required
                      fullWidth
                      margin="normal"
                      name="fullname"
                      autoComplete="fullname"
                      autoFocus
                      placeholder="Full Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <br></br> <br></br>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      {sendOTP ? (
                        <Button
                          style={{
                            fontSize: "15px",
                            marginTop: "-5px",
                            marginRight: "15px",
                            height: "35px",
                            backgroundColor: "#FFA500",
                          }}
                          onClick={() => {
                            sendOTPonborder();
                          }}
                          variant="contained"
                          color="primary"
                        >
                          <span
                            style={{
                              marginTop: "3px",
                            }}
                          >
                            SEND OTP
                          </span>
                          <ChevronRightIcon
                            style={{
                              fontSize: "30px",
                              color: "white",
                              marginRight: "-10px",
                            }}
                          />
                        </Button>
                      ) : (
                        ""
                      )}
                      <Button
                        style={{
                          fontSize: "15px",
                          marginTop: "-5px",
                          height: "35px",
                          backgroundColor: "#FFA500",
                        }}
                        onClick={() => {
                          registerNewUser();
                        }}
                        variant="contained"
                        color="primary"
                      >
                        <span
                          style={{
                            marginTop: "3px",
                          }}
                        >
                          Register
                        </span>
                        <ChevronRightIcon
                          style={{
                            fontSize: "30px",
                            color: "white",
                            marginRight: "-10px",
                          }}
                        />
                      </Button>
                    </div>
                  </Grid>
                  <Grid item md={6}>
                    <h4>Send Notifications</h4>
                    <Grid container spacing={1}>
                      <Grid item md={4}>
                        {selectedNoti == "EVERYONE" ? (
                          <Button
                            fullWidth
                            value="EVERYONE"
                            onClick={handlePageSelect}
                            p={2}
                            style={{
                              marginTop: "-15px",
                              backgroundColor: "#1434A4",
                              color: "white",
                              height: "25px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                letterSpacing: "1.5px",
                              }}
                            >
                              EVERYONE
                            </h3>
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            value="EVERYONE"
                            onClick={handlePageSelect}
                            p={2}
                            style={{
                              height: "25px",
                              marginTop: "-15px",
                              backgroundColor: "#d7ecff",
                              color: "white",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                color: "#084594",
                              }}
                            >
                              EVERYONE
                            </h3>
                          </Button>
                        )}
                      </Grid>
                      <Grid item md={4}>
                        {selectedNoti == "USERS" ? (
                          <Button
                            fullWidth
                            value="USERS"
                            onClick={handlePageSelect}
                            p={2}
                            style={{
                              marginTop: "-15px",
                              backgroundColor: "#1434A4",
                              color: "white",
                              height: "25px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                letterSpacing: "1.5px",
                              }}
                            >
                              USERS
                            </h3>
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            value="USERS"
                            onClick={handlePageSelect}
                            p={2}
                            style={{
                              height: "25px",
                              marginTop: "-15px",
                              backgroundColor: "#d7ecff",
                              color: "white",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                color: "#084594",
                              }}
                            >
                              USERS
                            </h3>
                          </Button>
                        )}
                      </Grid>
                      <Grid item md={4}>
                        {selectedNoti == "VENDORS" ? (
                          <Button
                            fullWidth
                            value="VENDORS"
                            onClick={handlePageSelect}
                            p={2}
                            style={{
                              marginTop: "-15px",
                              backgroundColor: "#1434A4",
                              color: "white",
                              height: "25px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                letterSpacing: "1.5px",
                              }}
                            >
                              VENDORS
                            </h3>
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            value="VENDORS"
                            onClick={handlePageSelect}
                            p={2}
                            style={{
                              height: "25px",
                              marginTop: "-15px",
                              backgroundColor: "#d7ecff",
                              color: "white",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                color: "#084594",
                              }}
                            >
                              VENDORS
                            </h3>
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                    <TextField
                      style={{ backgroundColor: "white", borderRadius: "6px" }}
                      variant="outlined"
                      required
                      fullWidth
                      margin="normal"
                      name="title"
                      autoComplete="title"
                      autoFocus
                      placeholder="Title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                    <TextField
                      style={{
                        backgroundColor: "white",
                        borderRadius: "6px",
                      }}
                      variant="outlined"
                      required
                      fullWidth
                      margin="normal"
                      name="message"
                      autoComplete="message"
                      autoFocus
                      placeholder="message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />{" "}
                    <br></br> <br></br>
                    <Button
                      style={{
                        fontSize: "15px",
                        marginTop: "-5px",
                        height: "35px",
                        backgroundColor: "#1434A4",
                      }}
                      onClick={() => {
                        sendNotification();
                      }}
                      variant="contained"
                      color="primary"
                    >
                      <span
                        style={{
                          marginTop: "3px",
                        }}
                      >
                        SEND NOTIFICATION
                      </span>
                      <ChevronRightIcon
                        style={{
                          fontSize: "30px",
                          color: "white",
                          marginRight: "-10px",
                        }}
                      />
                    </Button>
                  </Grid>
                </Grid>{" "}
              </div>
            </Fragment>
          );
        }
      } else {
        createAlert("ERROR", "Permission denied.", "Please Login as manager");
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Register;
