import React, { useEffect, Fragment, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Router, Route, Routes, useNavigate } from "react-router-dom";
import Login from "./pages/Login";
import Forgot from "./pages/Forgot";
import Dashboard from "./pages/Dashboard";
import Support from "./pages/Support";
import Settings from "./pages/Settings";
import Loader from "./pages/Loader";
import Feedback from "./pages/Feedback";
import Logout from "./pages/Logout";
import Notifications from "./pages/Notifications";
import Oops from "./pages/Oops";
import Initialize from "./pages/Initialize";
import Refunds from "./pages/Refunds";
import AddManager from "./pages/AddManager";
import Register from "./pages/Register";
import Payments from "./pages/Payments";
import Orders from "./pages/Orders";
import Profiles from "./pages/Profiles";

import { loadUser } from "./actions/auth";

function App() {
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: "USER_LOADING" });
    dispatch(loadUser());
  }, []);

  if (state.auth.loginFailed) {
    navigate("/login", { replace: true });
    dispatch({ type: "RESET_REDIRECT_LOGIN" });
  }

  return (
    <Routes>
      <Route exact path="/" element={<Loader />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgotpassword" element={<Forgot />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/support" element={<Support />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/feedback" element={<Feedback />} />
      <Route path="/notifications" element={<Notifications />} />
      <Route path="/initialize" element={<Initialize />} />
      <Route path="/profiles" element={<Profiles />} />
      <Route path="/orders" element={<Orders />} />
      <Route path="/payments" element={<Payments />} />
      <Route path="/register" element={<Register />} />
      <Route path="/refunds" element={<Refunds />} />

      <Route path="*" element={<Oops />} />
    </Routes>
  );
}

export default App;
